import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

export default function renderInstantTransferIneligible(modalContext) {
  const { updateModalComponent, pushModalStack, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(InstantTransferIneligible);
  pushModalStack(() => renderInstantTransferIneligible(modalContext));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
}

const InstantTransferIneligible = () => {
  const { popModalStack } = useModalContext();

  return (
    <AlertConfirmationModal
      variant="alert-neutral"
      title="Instant Transfer Ineligible"
      description="We're sorry, but your account is not eligible for Instant Transfer at this time. Eligibility is determined by Astra's Security Team. Please try the 1-3 business day ACH option. If you have any specific questions or need assistance, please contact our Member Support Team."
      CTAButtonText="OK"
      onCTAButtonClick={() => popModalStack(1)}
      hideSecondaryCTAButton={true}
    />
  );
};
