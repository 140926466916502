import PrimaryButton from "../../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../../Utils/constants";

import { PersonHoldingHouseRoof } from "../../../../Assets/Images";

import "./opted-in.scss";

export default function renderOptedIn(modalContext) {
  const { updateModalComponent, setModalVariant, onModalClose, setModalClassName } = modalContext;

  updateModalComponent(() => {
    return (
      <>
        <div className="modal-image mb-4">
          <img src={PersonHoldingHouseRoof} alt="" />
        </div>
        <div className="modal-dialog-content">
          <h3 className="modal-status-title mb-3">You’ve successfully enrolled in Stake Credit Builder</h3>
          <ul className="benefits-list">
            <li>You will receive a confirmation text and a confirmation email.</li>
            <li>
              We will notify you every time we report one of your on time payments to help boost your credit score.
            </li>
            <li>
              We will only report your on-time payments. If you do not pay on time, we will not report anything to the
              credit bureaus.
            </li>
          </ul>
        </div>
        <div className="action-container">
          <PrimaryButton className="blue-theme" onClick={onModalClose}>
            Continue
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("opted-in-benefits hide-header");
}
