import { useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants, nilYearLongMonthDateFormat, numericDateFormat } from "../../../Utils/constants";

import { appendISOTime, formatCurrency, formatDate } from "../../../Utils/helpers";

export default function renderAuthorizeTransfer(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, pushModalStack } = modalContext;

  pushModalStack(() => renderAuthorizeTransfer(modalContext, props));
  updateModalComponent(() => <AuthorizeTransfer {...props} />);
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Authorize this transaction");
}

const AuthorizeTransfer = (props) => {
  const {
    stake_account_number_last4,
    stake_routing_number,
    selectedAccount,
    selectedAstraCard,
    clearingTransactionDate,
    amount,
    isRecurringDeposit = false,
    recurringDepositPreferences,
    AuthorizeCallback,
  } = props;

  const modalContext = useModalContext();

  const { popModalStack } = modalContext;

  const [isLoading, setIsLoading] = useState(false);

  const formatCurrentDate = useMemo(() => {
    return formatDate(new Date(), numericDateFormat);
  }, []);

  const formatRecurringStartDate = useMemo(() => {
    return !!recurringDepositPreferences?.start_date
      ? formatDate(appendISOTime(recurringDepositPreferences.start_date), nilYearLongMonthDateFormat)
      : "";
  }, [recurringDepositPreferences?.start_date]);

  const handleAuthorize = () => {
    setIsLoading(true);
    AuthorizeCallback();
  };

  return (
    <>
      <div className="modal-dialog-content">
        <span className="modal-content-text mt-sm-3 mt-2">
          By selecting authorize, I authorize <strong>{formatCurrency(amount)}</strong> to be debited{" "}
          {!isRecurringDeposit ? (
            <>
              on <strong className="white-space-nowrap">{formatCurrentDate}</strong> from my bank account below.
            </>
          ) : (
            <>
              on a {recurringDepositPreferences?.frequency_type?.toLowerCase()} basis starting on{" "}
              <span className="white-space-nowrap">{formatRecurringStartDate}</span>.
            </>
          )}
        </span>
        <span className="modal-content-text mt-6 mb-4">
          {!!selectedAstraCard
            ? "Transfer speed depends on your bank and could take up to 30 minutes."
            : isRecurringDeposit
            ? "Transfers take one business day to process.\nTransfers set up on a weekend, or holiday, will occur on the next business day."
            : `Your transfer is expected to arrive on ${clearingTransactionDate}.`}
        </span>
        <div className="account-detail-direction-label mb-3">From</div>
        <div className="account-detail-card mb-sm-6 mb-4">
          <div className="account-detail">
            <div className="account-detail-label">
              {!!selectedAstraCard ? selectedAstraCard.card_company : selectedAccount?.name}
            </div>
            <div className="account-detail-value">
              (...{!!selectedAstraCard ? selectedAstraCard.last_four_digits : selectedAccount?.mask ?? "XXXX"})
            </div>
          </div>
          {!selectedAstraCard && !!stake_routing_number && (
            <div className="account-detail">
              <div className="account-detail-label">Routing Number</div>
              <div className="account-detail-value">{selectedAccount?.routing ?? "XXXX"}</div>
            </div>
          )}
        </div>
        <div className="account-detail-direction-label mb-3">To</div>
        <div className="account-detail-card mb-sm-6 mb-5">
          <div className="account-detail">
            <div className="account-detail-label">Stake</div>
            <div className="account-detail-value">(...{stake_account_number_last4 ?? "XXXX"})</div>
          </div>
          <div className="account-detail">
            <div className="account-detail-label">Routing Number</div>
            <div className="account-detail-value">{stake_routing_number ?? "XXXX"}</div>
          </div>
        </div>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={handleAuthorize} disabled={isLoading}>
          Authorize {isLoading && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={() => popModalStack(1)}>
          Cancel
        </PrimaryButton>
      </div>
    </>
  );
};
