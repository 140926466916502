import { useCallback, useEffect, useRef, useState } from "react";
import PinwheelModal from "@pinwheel/react-modal";

import { renderPayrollAdditionMaxLimitExceeded } from "../Organisms/Modals/Direct Deposit/payroll-addition-max-limit-exceeded";

import { useModalContext } from "../Contexts/ModalContext";

import { usePayrollAccounts } from "./Queries";
import { usePayrollLinkToken, useUpdatePayrollAccountToken } from "./Mutations";

import { dispatchAPIErrorToast } from "../Utils/helpers";

export default function usePinWheel(isPayrollEnabled) {
  const refetchTimeoutRef = useRef(null);

  const [payrollToken, setPayrollToken] = useState(null);

  const [refetchingPayrollDataWithDebounce, setRefetchingPayrollDataWithDebounce] = useState(false);

  const modalContext = useModalContext();

  const {
    isFetching: isFetchingPayrollAccounts,
    isLoading: isLoadingPayrollAccounts,
    data: payrollAccountsData,
    refetch: refetchPayrollAccounts,
  } = usePayrollAccounts({
    enabled: isPayrollEnabled,
    onSettled: () => setRefetchingPayrollDataWithDebounce(false),
  });

  useEffect(() => () => !!refetchTimeoutRef.current && clearTimeout(refetchTimeoutRef.current), []);

  const {
    isLoading: isGettingPayrollLinkToken,
    mutate: GetPayrollLinkToken,
    reset: resetGetPayrollLinkToken,
  } = usePayrollLinkToken({
    onSuccess: ({ data }) => {
      setPayrollToken(data?.data?.token);
      resetGetUpdatePayrollAccountToken();
    },
    onError: (err) => {
      if (err?.response?.status === 403) {
        renderPayrollAdditionMaxLimitExceeded(modalContext);
      } else {
        dispatchAPIErrorToast();
      }
    },
  });

  const {
    isLoading: isGettingUpdatePayrollAccountToken,
    variables: updationAccountId,
    mutate: GetUpdatePayrollAccountToken,
    reset: resetGetUpdatePayrollAccountToken,
  } = useUpdatePayrollAccountToken({
    onSuccess: ({ data }) => {
      setPayrollToken(data?.data?.token);
      resetGetPayrollLinkToken();
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const refetchOnDebounce = () => {
    setRefetchingPayrollDataWithDebounce(true);
    refetchTimeoutRef.current = setTimeout(() => refetchPayrollAccounts(), 5000);
  };

  const resetAll = () => {
    setPayrollToken(null);
    document.body.classList.remove("pinwheel-modal-takeover");
    resetGetPayrollLinkToken();
    resetGetUpdatePayrollAccountToken();
  };

  const PinwheelComponent = useCallback(
    () => (
      <PinwheelModal linkToken={payrollToken} open={!!payrollToken} onSuccess={refetchOnDebounce} onExit={resetAll} />
    ),
    //eslint-disable-next-line
    [payrollToken]
  );

  return {
    payrollToken,
    payrollAccountsData,
    updationAccountId,
    setPayrollToken,
    isFetchingPayrollAccounts,
    isLoadingPayrollAccounts,
    isGettingPayrollLinkToken,
    isGettingUpdatePayrollAccountToken,
    refetchingPayrollDataWithDebounce,
    GetPayrollLinkToken,
    GetUpdatePayrollAccountToken,
    PinwheelComponent,
  };
}
