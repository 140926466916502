import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";
import { formatCurrency } from "../../../Utils/helpers";

export default function renderRentMatch(modalContext, rentMatch, nextThirdTuesday) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  updateModalComponent(() => (
    <>
      <div className="modal-dialog-content">
        <h1 className="modal-billboard-text mb-2">{formatCurrency(rentMatch)}</h1>
        <span className="modal-content-text text-center mb-0">
          Stake gives you Cash Back for paying your rent on time. Your next rent match is {nextThirdTuesday}.
        </span>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={onModalClose}>
          Okay
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Rent Match");
}
