import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useAgreeToS } from "../../../CustomHooks/Mutations";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { PersonWithKeyIllustration } from "../../../Assets/Images";

import "./terms-of-service.scss";

export default function renderTermsOfService(modalContext) {
  const { updateModalComponent, setModalVariant, setPreventCloseOnBackdropClick, setModalClassName } = modalContext;

  updateModalComponent(TermsOfService);
  setPreventCloseOnBackdropClick(true);
  setModalClassName("terms-of-service hide-header");
  setModalVariant(modalVariants.flexDialog);
}

const TermsOfService = () => {
  const { onModalClose } = useModalContext();

  const queryClient = useQueryClient();

  const { isLoading: isAgreeingToS, mutate: AgreeToS } = useAgreeToS({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.deals);
      onModalClose();
    },
    onError: () => dispatchAPIErrorToast({ key: "agreeingToS" }),
  });

  return (
    <>
      <img className="modal-image person-with-key-illustration" src={PersonWithKeyIllustration} alt="" />
      <div className="modal-dialog-content">
        <h3 className="text-2xl-semibold color--charcoal mt-6 mb-0">Updated Terms of Service and Privacy Policy</h3>
        <p className="text-base-regular color--charcoal mt-2">
          By clicking "Agree", I agree to to Stake's updated{" "}
          <a
            className="color--stake-blue"
            href="https://www.stakerent.com/legal/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            className="color--stake-blue"
            href="https://www.stakerent.com/legal/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={() => AgreeToS()} disabled={isAgreeingToS}>
          Agree {isAgreeingToS && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};
