import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../Components/Buttons/primary-button";

import { useModalContext } from "../../Contexts/ModalContext";

import { modalVariants } from "../../Utils/constants";

import { CircleExclamation } from "../../Assets/Icons";

export default function renderAlertConfirmationModal(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <AlertConfirmationModal {...props} />);
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
}

export const AlertConfirmationModal = ({
  variant = "alert-error",
  title,
  description,
  CTAButtonText,
  secondaryCTAButtonText,
  onCTAButtonClick,
  onSecondaryCTAButtonClick,
  hideSecondaryCTAButton = false,
  isCTALoading = false,
}) => {
  const { onModalClose } = useModalContext();

  return (
    <>
      <div className="modal-dialog-content">
        <CircleExclamation className={`modal-status-icon ${variant} mb-4`} />
        <h3 className="modal-status-title mt-5 mb-2">{title}</h3>
        <span className="modal-content-text">{description}</span>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton
          className={variant === "alert-error" ? "red-theme" : "blue-theme"}
          onClick={onCTAButtonClick}
          disabled={isCTALoading}
        >
          {CTAButtonText} {isCTALoading && <CircularProgress />}
        </PrimaryButton>
        {!hideSecondaryCTAButton && (
          <PrimaryButton
            className="secondary-colors"
            onClick={!!onSecondaryCTAButtonClick ? onSecondaryCTAButtonClick : onModalClose}
          >
            {secondaryCTAButtonText}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
