import { createContext, useContext, useState } from "react";

import { appUtilityKeys } from "../Utils/constants";

import { LocalStorage, saveAndUpdateUserOnLogin } from "../Utils/helpers";

const AppUtilityContext = createContext();

export const useAppUtilityContext = () => {
  const appUtilityContextData = useContext(AppUtilityContext);
  if (appUtilityContextData === undefined) {
    throw new Error("useAppUtilityContext must be used within a AppUtilityContextProvider");
  }
  return appUtilityContextData;
};

export const AppUtilityContextProvider = ({ children }) => {
  const [appUtilityProps, setAppUtilityProps] = useState({ authKey: LocalStorage.read(appUtilityKeys.authKey) });

  const clearAppUtilityState = (keys = []) =>
    setAppUtilityProps((p) => {
      const t = { ...p };
      keys.forEach((key) => {
        delete t?.[key];
      });
      return t;
    });

  const onUserCredentialsUpdate = ({ data }) => {
    saveAndUpdateUserOnLogin(data);
    setAppUtilityProps((p) => ({ ...p, authKey: data?.data?.[appUtilityKeys.authKey] }));
  };

  return (
    <AppUtilityContext.Provider
      value={{ onUserCredentialsUpdate, appUtilityProps, setAppUtilityProps, clearAppUtilityState }}
    >
      {children}
    </AppUtilityContext.Provider>
  );
};
