import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment";

import FormSwitch from "../../Components/Form Fields/switch.js";
import DatePickerField from "../../Components/Form Fields/date-picker-field.js";
import CustomRadioButton from "../../Components/Form Fields/radio-button.js";
import PrimaryButton from "../../Components/Buttons/primary-button.js";

import renderGetRecurringDepositAmountDetails from "../../Organisms/Modals/Recurring Deposits/get-recurring-deposit-amount-details.js";
import renderAuthorizeTransfer from "../../Organisms/Modals/Banking/authorize-transfer.js";
import renderStatusModal from "../../Organisms/Modals/status-modal.js";
import renderConfirmClearRecurringDeposit from "../../Organisms/Modals/Recurring Deposits/confirm-clear-recurring-deposit.js";
import renderMandateBankAccountAddition from "../../Organisms/Modals/Banking/mandate-bank-account-addition.js";

import withFormProvider from "../../HOC/withFormProvider.js";

import { useModalContext } from "../../Contexts/ModalContext.js";

import {
  useConnectedAccounts,
  useDeals,
  useGetRecurringDeposit,
  useStakeDebit,
} from "../../CustomHooks/Queries/index.js";
import { useActivateRecurringDeposit } from "../../CustomHooks/Mutations/index.js";

import {
  appendISOTime,
  formatCurrency,
  formatDate,
  getNumberWithOrdinal,
  getRecurringDepositDefaultPreferences,
} from "../../Utils/helpers.js";
import {
  FailedRecurringDepositStatusModalProps,
  longMonthDateFormat,
  nilYearLongMonthDateFormat,
  SuccessfulRecurringDepositStatusModalProps,
} from "../../Utils/constants.js";

import { ArrowLeftCircle } from "../../Assets/Icons/index.js";

import "./recurring-deposits.scss";

export default function RecurringDepositsPage() {
  const recurringDepositAttributesRef = useRef(null);

  const navigate = useNavigate();

  const modalContext = useModalContext();

  const { onModalClose } = modalContext;

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const {
    isLoading: isLoadingRecurringDeposit,
    data: recurringDeposit,
    refetch: refetchRecurringDeposit,
  } = useGetRecurringDeposit();

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const onAddCashSuccess = (description) =>
    renderStatusModal(modalContext, {
      ...SuccessfulRecurringDepositStatusModalProps,
      description,
      onCTAButtonClick: () => onModalClose(),
      onSecondaryCTAButtonClick: () => {
        navigate("/banking");
        onModalClose();
      },
    });

  const openAuthorizeTransferModal = () =>
    renderAuthorizeTransfer(modalContext, {
      stake_account_number_last4: stakeDebit?.data?.data?.account_number?.slice(-4),
      stake_routing_number: stakeDebit?.data?.data?.routing_number,
      clearingTransactionDate: deals?.data?.content?.transaction_clearing_date,
      isRecurringDeposit: true,
      amount: recurringDepositAttributesRef.current?.preferences?.amount,
      selectedAccount: recurringDepositAttributesRef.current?.selectedAccount,
      recurringDepositPreferences: recurringDepositAttributesRef.current?.preferences,
      AuthorizeCallback: () => ActivateRecurringDeposit(recurringDepositAttributesRef.current?.preferences),
    });

  const { mutate: ActivateRecurringDeposit } = useActivateRecurringDeposit({
    onSuccess: (_, v) => {
      onAddCashSuccess(
        `Your first deposit is scheduled for ${formatDate(appendISOTime(v.start_date), nilYearLongMonthDateFormat)}.`
      );
      refetchRecurringDeposit();
    },
    onError: () =>
      renderStatusModal(modalContext, {
        ...FailedRecurringDepositStatusModalProps,
        onCTAButtonClick: () => openAuthorizeTransferModal(),
        onSecondaryCTAButtonClick: () => onModalClose(),
      }),
  });

  const onSubmit = ({ startDate, frequency }) => {
    renderGetRecurringDepositAmountDetails(modalContext, {
      onSchedule: ({ cashAmount, selectedAccount }) => {
        recurringDepositAttributesRef.current = {
          selectedAccount,
          preferences: {
            amount: cashAmount,
            frequency_type: frequency,
            start_date: startDate,
            originating_account_id: selectedAccount.account_id,
          },
        };
        openAuthorizeTransferModal();
      },
    });
  };

  const isLoading = isLoadingDeals || isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingRecurringDeposit;

  return (
    <div className="recurring-deposits-page">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button">
                <ArrowLeftCircle onClick={navigate.bind(this, -1)} />
              </button>
              <h3 className="section-title">Recurring Deposit</h3>
              <div className="dummy" />
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <RecurringDepositsFormWrapped
                recurringDepositAPIData={recurringDeposit?.data?.data}
                isBankAccountConnected={isBankAccountConnected}
                onSubmit={onSubmit}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export const RecurringDeposits = ({ recurringDepositAPIData, isBankAccountConnected, onSubmit }) => {
  const { register, watch, handleSubmit } = useFormContext();

  const modalContext = useModalContext();

  const [isSettingUpRecurringDeposit, setIsSettingUpRecurringDeposit] = useState(false);

  const frequencyProps = register("frequency", {
    required: true,
  });

  const startDate = watch("startDate");
  const frequency = watch("frequency");

  const recurringDepositFrequencyOptions = useMemo(() => {
    const date = moment(startDate);
    const dayOfTheWeek = date.format("dddd");
    const dateOfTheMonth = getNumberWithOrdinal(date.date());

    return [
      { title: "Every Week", description: `Deposits every ${dayOfTheWeek}.`, value: "Weekly" },
      { title: "Every Two Week", description: `Deposits every second ${dayOfTheWeek}.`, value: "Biweekly" },
      { title: "Every Month", description: `Deposits on the ${dateOfTheMonth} of each month.`, value: "Monthly" },
    ];
  }, [startDate]);

  const formattedExistingRecurringDeposit = useMemo(() => {
    return {
      active: recurringDepositAPIData?.active ?? false,
      ...(recurringDepositAPIData?.active ?? false
        ? (() => {
            const frequencyString =
              recurringDepositAPIData?.frequency_type === "Weekly"
                ? "week"
                : recurringDepositAPIData?.frequency_type === "Biweekly"
                ? "two weeks"
                : recurringDepositAPIData?.frequency_type === "Monthly"
                ? "month"
                : "";

            const recurringStartDate = moment(recurringDepositAPIData?.start_date);
            const recurringDayOfTheWeek = recurringStartDate.format("dddd");

            return {
              frequency: `Every ${frequencyString} on ${recurringDayOfTheWeek}`,
              amount: formatCurrency(recurringDepositAPIData?.amount),
              accountNickName: `${recurringDepositAPIData?.originating_account_name} (...${recurringDepositAPIData?.originating_account_mask})`,
              startDate: formatDate(recurringDepositAPIData?.start_date, longMonthDateFormat),
              // nextTransferDate: getRecurringNextTransferDate(recurringDepositAPIData),
            };
          })()
        : {}),
    };
  }, [recurringDepositAPIData]);

  const handleRecurringDepositSetup = () => {
    if (formattedExistingRecurringDeposit?.active) {
      renderConfirmClearRecurringDeposit(modalContext);
    } else if (isBankAccountConnected === false) {
      renderMandateBankAccountAddition(modalContext, {
        title: "Bank Account Required",
        description:
          "To set up a recurring deposit, you must first connect a bank account. Please add your bank account to continue.",
        onSuccess: () => setIsSettingUpRecurringDeposit(true),
      });
    } else {
      setIsSettingUpRecurringDeposit((p) => !p);
    }
  };

  const onRecurringDepositAdditionFormSubmit = () => onSubmit({ startDate, frequency });

  const disableSubmitButton = !(startDate ?? "") || !(frequency ?? "");

  return (
    <form className="content" onSubmit={handleSubmit(onRecurringDepositAdditionFormSubmit)} autoComplete="off">
      <div className="top-container">
        <div className="recurring-deposit-card mt-sm-6 mt-2 py-5">
          <div className="card-row">
            <div className="card-detail">
              <div className="text-lg-medium color--charcoal mb-1">Recurring Deposit</div>
              <div className="text-xs-regular color--medium-grey mb-0">
                Automatically deposit to stake from your bank
              </div>
            </div>
            <FormSwitch
              checked={formattedExistingRecurringDeposit?.active || isSettingUpRecurringDeposit}
              onClick={handleRecurringDepositSetup}
            />
          </div>
        </div>
        {!formattedExistingRecurringDeposit?.active && isSettingUpRecurringDeposit && (
          <>
            <div className="text-lg-medium color--charcoal mt-sm-6 mt-4">Starts on</div>
            <div className="recurring-deposit-card mt-sm-4 mt-3">
              <div className="card-row">
                <div className="text-sm-medium color--charcoal">Select date</div>
                <DatePickerField
                  name="startDate"
                  format="ddd, MMM DD, YYYY"
                  defaultValue={moment().add(1, "days")}
                  datePickerProps={{ disablePast: true }}
                  textFieldProps={{ placeholder: "Start Date" }}
                  miniField={true}
                />
              </div>
            </div>
            <div className="text-lg-medium color--charcoal mt-sm-6 mt-4">Frequency</div>
            <div className="recurring-deposit-card mt-sm-4 mt-3">
              {recurringDepositFrequencyOptions.map((frequencyOption, idx) => (
                <React.Fragment key={idx}>
                  <div className="card-row">
                    <div className="card-detail">
                      <div className="text-sm-medium color--charcoal mb-1">{frequencyOption.title}</div>
                      <div className="text-xxs-regular color--medium-grey mb-0">{frequencyOption.description}</div>
                    </div>
                    <CustomRadioButton id={frequencyOption.value} value={frequencyOption.value} {...frequencyProps} />
                  </div>
                  <Divider />
                </React.Fragment>
              ))}
              <div className="text-xxs-regular color--medium-grey">
                Transfers take one business day to process.
                <br />
                Transfers set up on a weekend, or holiday, will occur on the next business day.
              </div>
            </div>
          </>
        )}
        {formattedExistingRecurringDeposit?.active && (
          <>
            <div className="text-base-medium color--charcoal mt-6">Recurring Deposit Summary</div>
            <div className="recurring-deposit-card mt-4 py-5">
              <div className="row row-gap-4">
                <div className="col-6">
                  <div className="text-xs-regular color--medium-grey mb-1">Transfer Amount</div>
                  <div className="text-sm-medium color--charcoal mb-0">{formattedExistingRecurringDeposit?.amount}</div>
                </div>
                <div className="col-6">
                  <div className="text-xs-regular color--medium-grey mb-1">Debit from</div>
                  <div className="text-sm-medium color--charcoal mb-0">
                    {formattedExistingRecurringDeposit?.accountNickName}
                  </div>
                </div>
                <div className="col-6">
                  <div className="text-xs-regular color--medium-grey mb-1">Start Date</div>
                  <div className="text-sm-medium color--charcoal mb-0">
                    {formattedExistingRecurringDeposit?.startDate}
                  </div>
                </div>
                {/* <div className="col-6">
                  <div className="text-xs-regular color--medium-grey mb-1">Next Transfer Date</div>
                  <div className="text-sm-medium color--charcoal mb-0">
                    {formattedExistingRecurringDeposit?.nextTransferDate}
                  </div>
                </div> */}
                <div className="col-6">
                  <div className="text-xs-regular color--medium-grey mb-1">Frequency</div>
                  <div className="text-sm-medium color--charcoal mb-0">
                    {formattedExistingRecurringDeposit?.frequency}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!formattedExistingRecurringDeposit?.active && isSettingUpRecurringDeposit && (
        <div className="bottom-container">
          <PrimaryButton className="blue-theme mt-sm-6 mt-4" type="submit" disabled={disableSubmitButton}>
            Set
          </PrimaryButton>
        </div>
      )}
    </form>
  );
};

const RecurringDepositsFormWrapped = withFormProvider(RecurringDeposits, {
  defaultValues: getRecurringDepositDefaultPreferences(),
});
