import { CoreAPIRequest, StakeAPIRequest } from "./web-api-handler";

import { getAPIConfig, LocalStorage } from "./helpers";
import { appUtilityKeys } from "./constants";

export const APIhandler = {
  login: {
    requestLogin2FACode: (payload) => StakeAPIRequest.post("/mobile/loginRequestSms", payload),
    loginWith2FACode: (payload) => StakeAPIRequest.post("/mobile/loginSms", payload),
    sendVerificationCode: (uniqueContactID) =>
      CoreAPIRequest.post(`/api/resident/send_verification_code/${uniqueContactID}`),
  },
  alternativeLogin: {
    getAlternativeLoginFlag: () => CoreAPIRequest.get(`/api/helpers/alternative_login_flag/`),

    // TODO: Update this endpoint to be "multipart/form" instead of "application/json"
    // payload must be on the form of:
    // { form: { "address": "Address", "full_name": "full name", "birth_date": "01/01/20XX", "rent_amount": 1000.0, "move_in_date": "01/01/20xx" }  }
    alternativeIdentifyVerify: (payload) => CoreAPIRequest.post(`/api/residents/alternative_identify_verify/`, payload),

    // This endpoint must be a Content-type: multipart/form, so the payload would be:
    // payload example: { "form": { "email":"email@email.com", "phoneNumber": "+15551234567", "userId": "1231231231" } }
    loginViaAlternativeLogin: (payload) => StakeAPIRequest.post(`/mobile/alternativeLogin`, payload),
  },
  tokens: {
    getTransferToken: () => CoreAPIRequest.get("/mobile/get_transfer_token/", getAPIConfig(["userId"])),
    createLinkToken: () => CoreAPIRequest.post("/mobile/v2/create_link_token/", {}, getAPIConfig(["user"])),
    setAccessToken: (payload) => CoreAPIRequest.post("/mobile/set_access_token/", payload),
  },
  logout: {
    logout: () => StakeAPIRequest.post("/mobile/logout", {}, getAPIConfig(["xAccessToken"])),
  },
  platformSummary: {
    getMobileAppSummary: () => CoreAPIRequest.get("/api/helpers/itunes_data/"),
    getPlatformSummaryStats: () => CoreAPIRequest.get("/helpers/platform_summary_stats/"),
  },
  onboarding: {
    markOnboardingSeen: () => CoreAPIRequest.post("mobile/mark_onboarding_seen/", {}),

    // TODO: Verify "auth" token on header
    // Each options is a string, but as financial goals and spendings have multiple options,
    // each selection is seperated with a ";"
    // Payload example: { "financial_goals": "save;invest;economic freedom", "spending_preferences": "spend all;payments;vacations" }
    saveSpendingFinancialGoals: (userId, payload) =>
      CoreAPIRequest.post(`/resident/${userId}/spending_preferences_and_financial_goals/`, payload),
  },
  deals: {
    requestDeals: () => CoreAPIRequest.post(`/mobile/deals/`, {}, getAPIConfig(["userId", "userEmail"])),
  },
  unclaimedTransactions: {
    getUnclaimedTransactions: () =>
      CoreAPIRequest.get("/mobile/unclaimed_transactions", getAPIConfig(["transferToken"])),
    claimUnclaimedTransactions: () =>
      CoreAPIRequest.post("/mobile/unclaimed_transactions/claim", {}, getAPIConfig(["transferToken"])),
  },

  bankConnections: {
    saveDefaultConnectedAcccount: (payload) =>
      CoreAPIRequest.post("/mobile/save_account_selection_for_user/", {
        account_id: payload.account_id,
        userId: payload.userId,
      }),
    getConnectedAcccounts: (payload) => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.post(
        "/mobile/get_accounts_for_user/",
        { userId: user._id },
        getAPIConfig(["transferToken", "userId"])
      );
    },
    getConnectedAcccountSelection: (payload) => {
      return CoreAPIRequest.post("/mobile/get_account_selection_for_user/", {}, getAPIConfig("userId"));
    },

    // TODO: Verify if we need this endpoint. Dont remember when we use this. Also, confirm that the auth token is getting assigned.
    resetConnectedAcccount: (payload) => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.post(
        `mobile/${user._id}/connected_accounts/reset`,
        {},
        getAPIConfig(["transferToken", "userId"])
      );
    },
  },

  stakeVisaDebit: {
    getStakeDebit: () => CoreAPIRequest.get("/mobile/stake_debit/", getAPIConfig(["transferToken"])),
    requestStakeDebitCard: () =>
      CoreAPIRequest.post("/mobile/stake_debit/request_card/", {}, getAPIConfig(["transferToken"])),
    getStakeDebitStatements: () =>
      CoreAPIRequest.get("/mobile/stake_debit/statements/list/", getAPIConfig(["transferToken"])),

    // TODO: Verify auth token on this one
    replaceCard: (depositAccountId) => CoreAPIRequest.post(`resident/${depositAccountId}/replace_card/`, {}),

    getStateDebitStatement: (statementId) =>
      CoreAPIRequest.get(`/mobile/stake_debit/statements/${statementId}/`, {
        ...getAPIConfig(["transferToken", "blob"]),
        responseType: "blob", // To handle PDF streaming
      }),

    requestVirtualStakeDebit: () =>
      CoreAPIRequest.post("/mobile/stake_debit/request_virtual_card/", {}, getAPIConfig(["transferToken"])),
    getCustomerTokenVerificationSMS: () =>
      CoreAPIRequest.get("/mobile/stake_debit/customer_token_verification_sms/", getAPIConfig(["transferToken"])),
    getCustomerTokenVerificationResponse: (payload) =>
      CoreAPIRequest.post(
        "/mobile/stake_debit/customer_token_verification_response/",
        payload,
        getAPIConfig(["transferToken"])
      ),
  },

  payroll: {
    // TODO: On all payroll endpoints, confirm if auth token in here is the link token or the transfer link token
    // Also confirm that all endpoints get assigned with the auth token on the header

    getPayrollLinkToken: () =>
      CoreAPIRequest.get("/mobile/stake_payroll/get_link_token/", getAPIConfig(["transferToken"])),
    getPayrollAccounts: () => CoreAPIRequest.get("/mobile/stake_payroll/", getAPIConfig(["transferToken"])),

    // TODO: Find out why we use this endpoint. No clue!!! :(
    getUpdatePayrollAccountToken: (accountId) =>
      CoreAPIRequest.get(`/mobile/stake_payroll/account/${accountId}/`, getAPIConfig(["transferToken"])),

    disconnectPayrollAccount: (accountId) =>
      CoreAPIRequest.patch(
        `/mobile/stake_payroll/account/${accountId}/disconnect/`,
        {},
        getAPIConfig(["transferToken"])
      ),
  },

  creditBuilder: {
    // TODO: Verify if the auth token is getting assigned on the header for all the credit reporting endpoints
    getCreditReporting: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.get(`mobile/residents/${user._id}/credit_reporting/`, getAPIConfig(["transferToken"]));
    },
    optInCreditReporting: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.post(
        `mobile/residents/${user._id}/credit_reporting/opt_in/`,
        {},
        getAPIConfig(["transferToken"])
      );
    },
    optOutCreditReporting: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.post(
        `mobile/residents/${user._id}/credit_reporting/opt_out/`,
        {},
        getAPIConfig(["transferToken"])
      );
    },
    getPaymentHistories: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.get(`api/resident_payment_histories/${user._id}/`, getAPIConfig(["transferToken"]));
    },
  },

  resident: {
    getUserAccount: () => StakeAPIRequest.get(`mobile/account`, getAPIConfig(["xAccessToken"])),
    updateEmailAddress: (payload) =>
      CoreAPIRequest.patch(`api/resident/${payload.userId}/update/`, { email: payload.emailAddress }),
    updatePhoneNumber: (payload) =>
      CoreAPIRequest.patch(`api/resident/${payload.userId}/update/`, { phone: payload.phoneNumber }),
    updateDateOfBirthOrSNN: ({ userId, dob, ssn }) =>
      CoreAPIRequest.patch(`api/resident/${userId}/update/`, { dob, ssn }),

    emailChangeRequestVerification: (payload) =>
      StakeAPIRequest.post(`/mobile/emailChangeRequestVerification`, payload, getAPIConfig(["xAccessToken"])),
    emailChangeVerification: (payload) =>
      StakeAPIRequest.post(`/mobile/emailChangeVerification`, payload, getAPIConfig(["xAccessToken"])),

    phoneNumberChangeRequestVerification: (payload) =>
      StakeAPIRequest.post(`/mobile/phoneNumberChangeRequestSms`, payload, getAPIConfig(["xAccessToken"])),
    phoneNumberChangeVerification: (payload) =>
      StakeAPIRequest.post(`/mobile/phoneNumberChangeSms`, payload, getAPIConfig(["xAccessToken"])),
  },

  transfers: {
    initiateVenmoTransfer: (userId, amount, auth) =>
      CoreAPIRequest.post("/mobile/initiate_venmo_transfer/", {}, { auth, amount, userId }),

    // Payload example: { "userId": "user id", "acctId": "123980123", "acctName": "Visa (7837)", "amount": 102.54 }
    initiatePlaidWithdrawal: (payload) =>
      CoreAPIRequest.post("/mobile/initiate_transfer/", payload, getAPIConfig("userId", "xAccessToken")),

    // TODO: make sure that "auth" token is getting assigned correctly on the header
    initiateACHDebit: ({ accountId, ...payload }) =>
      CoreAPIRequest.post(
        `mobile/connected_accounts/${accountId}/initiate_ach_debit/`,
        payload,
        getAPIConfig(["transferToken"])
      ),

    // Withdrawal endpoint... must be called after any previous endpoint that initiated a money transfer
    // NOTE: amount is float number with max 2 decimal places, but must be a string, ex: "123.23"
    withdrawal: (payload) => StakeAPIRequest.post(`/mobile/withdrawals/`, payload, getAPIConfig(["xAccessToken"])),
  },
  ledger: {
    getActivities: () => StakeAPIRequest.get(`mobile/activities/`, getAPIConfig(["xAccessToken"])),
  },

  astra: {
    // New endpoints
    createUserIntent: (payload) => CoreAPIRequest.post("/create_user_intent", payload, getAPIConfig(["formData"])),

    createTrustedAuthenticationToken: (payload) =>
      CoreAPIRequest.post("/create_trusted_authentication_token", payload, getAPIConfig(["formData"])),

    getAstraTokens: (payload) =>
      CoreAPIRequest.post("/authorize_astra_user_using_session_token", payload, getAPIConfig(["formData"])),

    refreshAstraTokens: (payload) => CoreAPIRequest.post("/get_astra_token", payload, getAPIConfig(["formData"])),

    fetchAstraCards: () => CoreAPIRequest.get("/fetch_astra_cards", getAPIConfig(["astraToken"])),

    fetchAstraAccounts: () => CoreAPIRequest.get("/fetch_astra_accounts", getAPIConfig(["astraToken"])),

    createTransferRoutine: (payload) =>
      CoreAPIRequest.post("/create_transfer_routine", payload, getAPIConfig(["astraToken", "formData"])),

    enableCardToCardPayment: (cardId, payload) =>
      CoreAPIRequest.patch(`/cards/${cardId}/enableCardToCardPayment`, payload, getAPIConfig()),

    deleteAstraCard: (cardId) => CoreAPIRequest.delete(`/delete_astra_card/${cardId}`, getAPIConfig(["astraToken"])),

    createAccountToCardTransferRoutineWithoutAuthToken: (payload) =>
      CoreAPIRequest.post("/create_account_to_card_transfer_routine", payload, getAPIConfig(["formData"])),

    createAccountToCardTransferRoutine: (payload) =>
      CoreAPIRequest.post(
        "/create_account_to_card_transfer_routine",
        payload,
        getAPIConfig(["astraToken", "formData"])
      ),

    createAstraAccount: (payload) =>
      CoreAPIRequest.post("/create_astra_account", payload, getAPIConfig(["astraToken", "formData"])),

    checkRoutesCapabilities: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.get(`/check_routes_capabilities/${user._id}`, getAPIConfig(["astraToken"]));
    },
  },

  recurringDeposits: {
    getRecurringDeposit: () => {
      const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
      return CoreAPIRequest.get(`mobile/residents/${user._id}/recurring_add_funds/`, getAPIConfig(["transferToken"]));
    },
    activateRecurringDeposit: ({ userId, ...payload }) =>
      CoreAPIRequest.post(
        `mobile/residents/${userId}/recurring_add_funds/activate/`,
        payload,
        getAPIConfig(["transferToken"])
      ),

    clearRecurringDeposit: ({ userId, ...payload }) =>
      CoreAPIRequest.patch(
        `mobile/residents/${userId}/recurring_add_funds/clear/`,
        payload,
        getAPIConfig(["transferToken"])
      ),
  },
  termsOfService: {
    agreeToS: (payload) => CoreAPIRequest.post("/user/update_tos_agreement/", payload, getAPIConfig(["transferToken"])),
  },
};
