import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";

export default function renderVirtualSVDApproved(modalContext) {
  const {
    updateModalComponent,
    pushModalStack,
    setModalVariant,
    setModalPromoComponent,
    setModalTitle,
    setModalClassName,
  } = modalContext;

  updateModalComponent(VirtualSVDApproved);
  pushModalStack(() => renderVirtualSVDApproved(modalContext));
  setModalPromoComponent(null);
  setModalTitle("");
  setModalClassName("position-close-button-over-content");
  setModalVariant(modalVariants.flexDialog);
}

const VirtualSVDApproved = () => {
  const { popModalStack } = useModalContext();

  return (
    <>
      <div className="modal-image svd-single-card" src={SVD_Single_Card} alt="">
        <img src={SVD_Single_Card} alt="" />
      </div>
      <div className="modal-dialog-content">
        <h3 className="modal-status-title mt-6 mb-5">Congratulations! You've been approved</h3>
        <ul className="benefits-list">
          <li>Your virtual Stake Visa Debit card is now available for use.</li>
          <li>You can request a physical card from the home screen if you'd like.</li>
          <li>You can view your virtual debit card number and security code from the home screen.</li>
        </ul>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={() => popModalStack(1)}>
          Continue
        </PrimaryButton>
      </div>
    </>
  );
};
