import renderAlertConfirmationModal from "../alert-confirmation-modal";

export default function renderInstantTransferLimitReached(modalContext) {
  const { onModalClose } = modalContext;

  renderAlertConfirmationModal(modalContext, {
    variant: "alert-neutral",
    title: "Transfer Limit Reached",
    description:
      "You've reached your instant transfer limit for the 24-hour period. A maximum of two instant transfers is allowed every 24 hours. If you have any questions or need assistance, please contact our Member Support Team.",
    CTAButtonText: "Ok",
    hideSecondaryCTAButton: true,
    onCTAButtonClick: () => onModalClose(),
  });
}
