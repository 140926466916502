import { ConnectedAccounts } from "../../../Pages/Connected Accounts";

import { modalVariants } from "../../../Utils/constants";

export default function renderSelectConnectedAccounts(modalContext, props) {
  const { updateModalComponent, setModalTitle, setModalVariant, pushModalStack } = modalContext;

  pushModalStack(() => renderSelectConnectedAccounts(modalContext, props));
  updateModalComponent(() => <SelectConnectedAccounts presentedForSelection {...props} />);
  setModalTitle("Connected Accounts");
  setModalVariant(modalVariants.fullScreenDialog);
}

export const SelectConnectedAccounts = (props) => {
  return <ConnectedAccounts {...props} />;
};
