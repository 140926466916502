import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useGetRecurringDeposit } from "../../../CustomHooks/Queries";

import { modalVariants } from "../../../Utils/constants";

export function renderDeleteConnectedAccount(modalContext, account, deleteConfirmationProps) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => (
    <DeleteConnectedAccount account={account} deleteConfirmationProps={deleteConfirmationProps} />
  ));
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
}

const DeleteConnectedAccount = ({ account, deleteConfirmationProps }) => {
  const { onModalClose } = useModalContext();

  const { isLoading: isLoadingRecurringDeposit, data: recurringDeposit } = useGetRecurringDeposit();

  return isLoadingRecurringDeposit ? (
    <ModalSkeleton className="mt-sm-6 mt-4" />
  ) : (
    <AlertConfirmationModal
      {...(recurringDeposit?.data?.data?.active &&
      account.account_id === recurringDeposit?.data?.data?.originating_account_id
        ? {
            variant: "alert-neutral",
            title: "Unable to remove bank account",
            description:
              "There is an active recurring deposit or scheduled payment linked to this account. Cancel them before removing the account.",
            CTAButtonText: "Got It",
            hideSecondaryCTAButton: true,
            onCTAButtonClick: () => onModalClose(),
          }
        : deleteConfirmationProps)}
    />
  );
};
