import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

export default function renderCurrentBalance(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, onModalClose } = modalContext;

  updateModalComponent(() => (
    <>
      <div className="modal-dialog-content">
        <span className="modal-content-text mb-2">
          The total amount of money recorded in your account including funds not yet available for use.
        </span>
        <span className="modal-content-text mb-0">
          This includes pending transactions, authorization holds, or other deposits that are not yet available.
        </span>
      </div>
      <div className="action-container">
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Close
        </PrimaryButton>
      </div>
    </>
  ));
  setModalVariant(modalVariants.tooltipDialog);
  setModalTitle("Current Balance");
}
