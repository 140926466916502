import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../Components/Buttons/primary-button.js";
import CustomRadioButton from "../../Components/Form Fields/radio-button.js";

import renderAlertConfirmationModal from "../../Organisms/Modals/alert-confirmation-modal.js";

import useAstra from "../../CustomHooks/useAstra.js";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext.js";

import { useDeleteAstraCard } from "../../CustomHooks/Mutations";

import {
  AmericanExpress,
  Discover,
  Mastercard,
  VisaLogo,
  DebitCards as DebitCardsImage,
  GreenCheckMarkIcon,
  RejectedIcon,
  PendingIcon,
  FlaggedIcon,
} from "../../Assets/Images/";
import { ArrowLeftCircle, AddCircle, Trash } from "../../Assets/Icons";

import { dispatchAPIErrorToast, getIsDebitCardExpired, LocalStorage } from "../../Utils/helpers.js";
import { APIQueryKeys, appUtilityKeys } from "../../Utils/constants.js";

import "./debit-cards.scss";

const RemoveConnectedAccountsModalProps = (cardNickName) => ({
  title: "Remove Card",
  description: `Are you sure you want to delete ${cardNickName}?`,
  CTAButtonText: "Remove",
  secondaryCTAButtonText: "Cancel",
});

const DebitCardItem = ({ card, selected, selectable = false, setSelectedDebitCardId, onSelect }) => {
  const modalContext = useModalContext();

  const queryClient = useQueryClient();

  const defaultCard = LocalStorage.read(appUtilityKeys.astraSelectedCardId) === card?.id;

  const capitalizedStatus = card.status.charAt(0).toUpperCase() + card.status.slice(1);

  const { isLoading: isDeleingAstraCard, mutate: DeleteAstraCard } = useDeleteAstraCard({
    onSuccess: () => queryClient.refetchQueries(APIQueryKeys.astraCards),
    onError: () => dispatchAPIErrorToast(),
  });

  const handleDeleteAstraDebitCard = (e, card) => {
    e.stopPropagation();
    renderAlertConfirmationModal(modalContext, {
      ...RemoveConnectedAccountsModalProps(card?.cardNickName),
      onCTAButtonClick: () => {
        DeleteAstraCard(card.id);
        const currentSelectionId = LocalStorage.read(appUtilityKeys.astraSelectedCardId);
        if (currentSelectionId === card.id) {
          LocalStorage.remove(appUtilityKeys.astraSelectedCardId);
        }
        modalContext.onModalClose();
      },
    });
  };

  const cardCompanyLogo = useMemo(() => {
    if (card.card_company === "Visa") {
      return <VisaLogo />;
    } else if (card.card_company === "AmericanExpress") {
      return <AmericanExpress />;
    } else if (card.card_company === "Discover") {
      return <Discover />;
    } else if (card.card_company === "Mastercard") {
      return <Mastercard />;
    } else {
      return <VisaLogo />;
    }
  }, [card.card_company]);

  const cardStatusIcon = useMemo(() => {
    if (card.status === "approved") {
      return <GreenCheckMarkIcon className="badge-icon" />;
    } else if (card.status === "rejected") {
      return <RejectedIcon className="badge-icon" />;
    } else if (card.status === "flagged") {
      return <FlaggedIcon className="badge-icon" />;
    } else if (card.status === "pending") {
      return <PendingIcon className="badge-icon" />;
    } else {
      return <RejectedIcon />;
    }
  }, [card.status]);

  const isDebitCardExpired = useMemo(() => getIsDebitCardExpired(card.expiration_date), [card.expiration_date]);

  const selectCardForPayment = () => {
    if (selectable) {
      onSelect?.(card.id);
    }
  };

  const setAsDefault = (e) => {
    e.stopPropagation();
    LocalStorage.write(appUtilityKeys.astraSelectedCardId, card.id);
    setSelectedDebitCardId(card.id);
    selectCardForPayment();
  };

  return (
    <div
      className={`debit-card-item${selected ? " selected" : ""}${selectable ? " selectable" : ""} mt-sm-6 mt-2`}
      onClick={selectCardForPayment}
    >
      <div className="card-details-container">
        <div className="card-info">
          <div className="card-logo">{cardCompanyLogo}</div>
          <div className="card-details">
            <span className="card-type">{`${card.card_company} (...${card.last_four_digits})`}</span>
            <span className="card-expiry">Exp. date: {`${card.expiration_date}`}</span>
          </div>
        </div>
        <div className="action-container">
          {defaultCard ? (
            <span className="default-badge">Default</span>
          ) : (
            !isDebitCardExpired && (
              <button className="set-default" onClick={setAsDefault}>
                Set as Default
              </button>
            )
          )}
          {isDebitCardExpired && <span className="expired-badge">Expired</span>}
          {isDeleingAstraCard ? (
            <CircularProgress className="deletion-in-progress" />
          ) : (
            <button className="delete" onClick={(e) => handleDeleteAstraDebitCard(e, card)}>
              <Trash />
            </button>
          )}
        </div>
      </div>
      {!isDebitCardExpired && (
        <>
          <div className="divider"></div>
          <div className="status-container">
            <div className={`status-badge ${card.status}`}>
              {cardStatusIcon}
              <span className="status-text">{capitalizedStatus}</span>
            </div>
            {selectable && <CustomRadioButton checked={selected} onChange={selectCardForPayment} />}
          </div>
        </>
      )}
    </div>
  );
};

export default function DebitCards() {
  const navigate = useNavigate();

  const { appUtilityProps, setAppUtilityProps, clearAppUtilityState } = useAppUtilityContext();

  const { enforceCardSelection = false } = appUtilityProps?.debitCards ?? {};

  const [selectedDebitCardId, setSelectedDebitCardId] = useState(
    LocalStorage.read(appUtilityKeys.astraSelectedCardId) ?? null
  );

  const {
    isInitialisingAstra,
    isCreatingUserIntent,
    isCreatingTrustedAuthenticationToken,
    isFetchingAstraCards,
    openAstraPortal,
    astraDebitCards,
  } = useAstra();

  const connectedDebitCards = useMemo(() => {
    const cards = astraDebitCards ?? [];
    const filteredCards = cards.filter((card) => !card.is_system_card);
    return filteredCards.map((card) => (
      <DebitCardItem
        key={card.id}
        card={card}
        selected={card.id === (!enforceCardSelection ? selectedDebitCardId : appUtilityProps?.selectedCardId ?? "")}
        selectable={enforceCardSelection}
        setSelectedDebitCardId={setSelectedDebitCardId}
        onSelect={(cardId) => {
          setAppUtilityProps((p) => ({ ...p, selectedCardId: cardId }));
        }}
      />
    )); //eslint-disable-next-line
  }, [astraDebitCards, selectedDebitCardId, enforceCardSelection, appUtilityProps?.selectedCardId]);

  const handleBackButtonClick = () => {
    if (enforceCardSelection) {
      clearAppUtilityState(["debitCards"]);
    }
    navigate(-1);
  };

  const isSettingUpAstra = isCreatingUserIntent || isCreatingTrustedAuthenticationToken;

  return (
    <div className={`debit-cards${enforceCardSelection ? " page-elevate" : ""}`}>
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={handleBackButtonClick}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Debit Cards</h3>
              {isSettingUpAstra ? (
                <CircularProgress />
              ) : (
                <button className="add-button" onClick={openAstraPortal}>
                  <AddCircle />
                </button>
              )}
            </div>
            {isInitialisingAstra || isFetchingAstraCards ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : connectedDebitCards.length > 0 ? (
              <div className="connected-debit-cards">{connectedDebitCards}</div>
            ) : (
              <div className="no-debit-cards mt-sm-6 mt-2">
                <div className="card-icon my-8">
                  <DebitCardsImage />
                </div>
                <h2 className="no-cards-title mb-1">No Debit Cards Linked</h2>
                <p className="no-cards-message mb-sm-6 mb-5">
                  You don’t have any debit cards connected to your account.
                </p>
                <PrimaryButton className="blue-theme" onClick={openAstraPortal} disabled={isCreatingUserIntent}>
                  Link a Debit Card {isCreatingUserIntent && <CircularProgress />}
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
