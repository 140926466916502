import { enqueueSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

import {
  appUtilityKeys,
  ErrorToastVariant,
  fontFace400,
  fontFace500,
  InfoToastVariant,
  neglectEndpointsForUnauthorisedErrors,
  SuccessToastVariant,
  WarningToastVariant,
} from "./constants";

import { CheckCircle, Cancel, Warning, Blitz, BankingOutline, Link, HourGlass } from "../Assets/Icons";
import moment from "moment/moment";

export const getToastVariantIcon = (type) => {
  switch (type) {
    case "success":
      return CheckCircle;
    case "error":
      return Cancel;
    case "warning":
      return Warning;
    case "info":
      return Warning;
    default:
      return () => <></>;
  }
};

export const getPayrollStatusIcon = (type) => {
  switch (type) {
    case "success":
      return CheckCircle;
    case "pending":
      return HourGlass;
    case "disconnected":
      return Link;
    default:
      return () => <></>;
  }
};

export const isActiveModule = (modulePath, currentPath) => {
  return currentPath.startsWith(modulePath);
};

export const isOnboardingModule = (pathname) => {
  return ["/login", "/welcome", "/unclaimed"].includes(pathname);
};

export const getHeaderBgColorForPage = (pathname) => {
  return pathname === "/banking" ? "dark" : "light";
};

//Local Storage CRUD Helper

export const LocalStorage = {
  write: (key, val) => {
    if (val && typeof val === "object") localStorage.setItem(key, JSON.stringify(val));
    else localStorage.setItem(key, val);
  },
  read: (key) => {
    const str = localStorage.getItem(key);
    if (str === null) {
      return null;
    }

    // Check if the value is a boolean string ("true" or "false")
    if (str === "true" || str === "false") {
      return str === "true";
    }

    // Check if the value is a JSON object or array
    if (["{", "["].includes(str.charAt(0))) {
      try {
        return JSON.parse(str);
      } catch (e) {
        console.error("Failed to parse JSON:", e);
        return null;
      }
    }

    // If it's neither a boolean nor a JSON object, return the string as is
    return str;
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
  clear: () => localStorage.clear(),
};

//Toast Dispatch helpers

export const dispatchSuccessToast = (message, options = {}) =>
  enqueueSnackbar(message, { variant: SuccessToastVariant, key: uuidv4(), preventDuplicate: true, ...options });

export const dispatchErrorToast = (message, options = {}) =>
  enqueueSnackbar(message ?? "Oops! Something went wrong!", {
    variant: ErrorToastVariant,
    key: uuidv4(),
    preventDuplicate: true,
    ...options,
  });

export const dispatchAPIErrorToast = (options) =>
  dispatchErrorToast(
    <>
      {"Something went wrong. Please reload the page. If the error persists, contact "}
      <a href="mailto:hello@stake.rent">hello@stake.rent</a>
    </>,
    options
  );

export const dispatchWarningToast = (message, options = {}) =>
  enqueueSnackbar(message, { variant: WarningToastVariant, key: uuidv4(), preventDuplicate: true, ...options });

export const dispatchInfoToast = (message, options = {}) =>
  enqueueSnackbar(message, { variant: InfoToastVariant, key: uuidv4(), preventDuplicate: true, ...options });

//Data Tranformation helpers

export const replaceNonNumericCharacters = (number) => number?.replace(/[^\d]/gi, "");

export const transformUSNumberToCountryCodeFormat = (number) => `+1${replaceNonNumericCharacters(number)}`;

export const removeCountryCodeFromPhoneNumber = (number) => number.replace("+1", "");

export const transformCountryCodeFormatToUSNumber = (number) => {
  var cleaned = removeCountryCodeFromPhoneNumber(number).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
};

export const getNumberFromCurrentAmount = (a) => Number(a.replace(/[^0-9.-]+/g, ""));

/*eslint no-control-regex: "off"*/
export const removeControlCharacters = (str) => str.replace(/[^\x00-\x7F]/g, "");

//API Request config builder

export const getAPIConfig = (builder = []) => {
  const headers = new Headers();

  if (builder.includes("authKey")) {
    const authKey = LocalStorage.read(appUtilityKeys.authKey);
    headers.append("auth", authKey);
  }

  if (builder.includes("xAccessToken")) {
    const authKey = LocalStorage.read(appUtilityKeys.authKey);
    headers.append("x-access-token", authKey);
  }

  if (builder.includes("transferToken")) {
    const transferToken = LocalStorage.read(appUtilityKeys.transferToken);
    headers.append("auth", transferToken);
  }

  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  if (builder.includes("userId") && user) {
    headers.append("userId", user._id ?? "");
  }

  if (builder.includes("user") && user) {
    headers.append("user", user._id ?? "");
  }

  if (builder.includes("userEmail")) {
    headers.append("user-email", user.email ?? "");
  }

  if (builder.includes("astraToken")) {
    const astraToken = LocalStorage.read(appUtilityKeys.astraToken) ?? "";
    headers.append("Authorization", astraToken);
  }

  if (builder.includes("formData")) {
    headers.append("Content-Type", "multipart/form-data");
  }

  return {
    headers: Object.fromEntries(headers.entries()),
  };
};

//Object Attribute Inject helper

export const injectAttributes = (obj, builder = []) => {
  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  if (builder.includes("userId") && user) {
    obj.userId = user._id;
  }

  return obj;
};

//API Interceptors
export const CoreAPIRequestInterceptor = (req) => {
  req.headers["dealsAPIKey"] = process.env.REACT_APP_CORE_API_KEY;
  return req;
};

export const APIErrorInterceptor = (err) => {
  if (!neglectEndpointsForUnauthorisedErrors.find((endpoint) => err?.request?.responseURL.endsWith(endpoint))) {
    if ([401, 403].find((e) => e === err?.response?.status)) {
      onSignOut();
      return;
    }
  }
  throw err;
};

//Formatting helpers
export const formatCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const formatNumberWithCentsToUSD = (amount) => {
  // Divide by 100 to move the last two digits into cents
  return formatCurrency(amount / 100);
};

export const formatDate = (dateString, options = {}) => {
  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateString));
};

export const getISOFormatDate = (date) => date?.toISOString().split("T")[0];

export const appendISOTime = (date) => `${date}T00:00:00`;

//SVD Status Checkers

export const isSVDApproved = (stakeDebit) => {
  return (
    !!stakeDebit &&
    (stakeDebit?.data?.status === "ApplicationApproved" ||
      stakeDebit?.data?.status === "DepositAccountCreated" ||
      stakeDebit?.data?.status === "DebitCardRequested" ||
      stakeDebit?.data?.status === "DebitCardActivated")
  );
};

export const isSVDPending = (stakeDebit) => {
  return (
    !!stakeDebit &&
    (stakeDebit?.data?.status === "ApplicationPending" ||
      stakeDebit?.data?.status === "ApplicationPendingAddressVerification" ||
      stakeDebit?.data?.status === "ApplicationPendingIDVerification" ||
      stakeDebit?.data?.status === "ApplicationPendingSSNVerification")
  );
};

export const hasSVDPhysicalCard = (stakeDebit) => {
  return !!stakeDebit && ["DebitCardRequested", "DebitCardActivated"].includes(stakeDebit?.data?.status);
};

export const isSVDCardRequested = (stakeDebit) => {
  return !!stakeDebit && stakeDebit?.data?.status === "DebitCardRequested";
};

export const isSVDDebitCardActivated = (stakeDebit) => {
  return !!stakeDebit && stakeDebit?.data?.status === "DebitCardActivated";
};

export const isSVDEligible = (stakeDebit) => {
  return !!stakeDebit && stakeDebit?.data?.status === "Eligible";
};

export const isSVDDenied = (stakeDebit) => {
  return !!stakeDebit && stakeDebit?.data?.status === "ApplicationDenied";
};

export const isSVDIneligible = (stakeDebit) => {
  return !!stakeDebit && stakeDebit?.data?.status === "Ineligible";
};

export const hasSVDPhysicalCardArrived = (arrivalDate) => {
  return new Date().getTime() >= arrivalDate?.getTime();
};

//Date time helpers

export function getThirdTuesdayOfMonth(y, m) {
  let date = new Date(y, m, 1);
  let dayOfWeek = date.getDay();
  let daysUntilFirstTuesday = (9 - dayOfWeek) % 7;
  date.setDate(1 + daysUntilFirstTuesday + 14); // 14 days after the first Tuesday
  return date;
}

export const getThirdTuesdayOfMonthCached = (() => {
  const cache = new Map();

  return (y, m) => {
    const key = `${y}-${m}`;
    if (cache.has(key)) {
      return cache.get(key);
    }

    let thirdTuesdayDate = getThirdTuesdayOfMonth(y, m);
    cache.set(key, thirdTuesdayDate);

    return thirdTuesdayDate;
  };
})();

export const isThirdTuesdayOfMonth = (d, m, y) => {
  const thirdTuesdayOfMonth = getThirdTuesdayOfMonthCached(y, m);
  return d === thirdTuesdayOfMonth.getDate();
};

export function getNextThirdTuesday() {
  const now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth();

  // Function to get the third Tuesday of a given month and year
  let thirdTuesday = getThirdTuesdayOfMonth(year, month);

  // If the third Tuesday has passed, get the third Tuesday of next month
  if (thirdTuesday < now) {
    month++;
    if (month > 11) {
      month = 0;
      year++;
    }
    thirdTuesday = getThirdTuesdayOfMonth(year, month);
  }

  return thirdTuesday;
}

//VGS Helpers

export const getVGSCardNumberRequestParams = (card_id, customer_token) => ({
  name: "secret-card-number",
  method: "GET",
  path: `/cards/${card_id}/secure-data/pan`,
  htmlWrapper: "text",
  jsonPathSelector: "data.attributes.pan",
  headers: {
    Authorization: `Bearer ${customer_token}`,
  },
});

export const getVGSCvvRequestParams = (card_id, customer_token) => ({
  name: "secret-cvv2",
  method: "GET",
  path: `/cards/${card_id}/secure-data/cvv2`,
  htmlWrapper: "text",
  jsonPathSelector: "data.attributes.cvv2",
  headers: {
    Authorization: `Bearer ${customer_token}`,
  },
});

export const getVGSCardNumberStyle = (fontSize) => ({
  ...fontFace500,
  color: "#282828",
  "font-family": "Poppins",
  "font-size": fontSize,
  "font-style": "normal",
  "font-weight": "500",
  "line-height": "48px",
});

export const getVGSFieldStyle = () => ({
  ...fontFace400,
  color: "#282828",
  "font-family": "Poppins",
  "font-size": "16px",
  "font-style": "normal",
  "font-weight": "400",
  "line-height": "24px",
});

export const getVGSCopyButtonStyle = () => ({
  ...fontFace500,
  color: "#282828",
  "font-family": "Poppins",
  "font-size": "16px",
  "font-style": "normal",
  "font-weight": "500",
  "line-height": "24px",
  cursor: "pointer",
});

//Misc helpers

export const onSignOut = () => {
  LocalStorage.remove(appUtilityKeys.authKey);
  LocalStorage.remove(appUtilityKeys.loggedInUserData);
  LocalStorage.remove(appUtilityKeys.transferToken);
  LocalStorage.remove(appUtilityKeys.astraToken);
  LocalStorage.remove(appUtilityKeys.astraRefreshToken);
  LocalStorage.remove(appUtilityKeys.astraIntentId);
  LocalStorage.remove(appUtilityKeys.lastAstraTokenFetchTime);
  LocalStorage.remove(appUtilityKeys.astraSelectedCardId);
  window.location.pathname = "/login";
};

export const getHasVisitedWelcomeScreenKey = () => {
  const userId = LocalStorage.read(appUtilityKeys.loggedInUserData)?._id;
  return appUtilityKeys.hasVisitedWelcomeScreen(userId);
};

export const getAnimateCurrentBalanceFromKey = () => {
  const userId = LocalStorage.read(appUtilityKeys.loggedInUserData)?._id;
  return appUtilityKeys.animateCurrentBalanceFrom(userId);
};

export const getTransferTypes = (dealsData) => {
  let instantDescription = `In a few minutes\n`;
  if (!(dealsData?.data?.currentDeal?.has_used_1_free_instant_transfer__c ?? true)) {
    instantDescription += `${
      dealsData?.data?.currentDeal?.has_used_1_free_instant_transfer__c ? 0 : 1
    } free use remaining`;
  }

  return [
    {
      type: "instant",
      Icon: Blitz,
      title: "Instant",
      description: instantDescription,
    },
    {
      type: "ach",
      Icon: BankingOutline,
      title: "1-3 biz days",
      description: `Estimated by ${dealsData?.content?.transaction_clearing_date}`,
    },
  ];
};

export const saveAndUpdateUserOnLogin = (data) => {
  LocalStorage.write(appUtilityKeys.authKey, data?.data?.[appUtilityKeys.authKey]);
  LocalStorage.write(appUtilityKeys.loggedInUserData, data?.data?.[appUtilityKeys.loggedInUserData]);

  if (!!window.uxc) {
    window.uxc.setUserIdentity(data?.data?.user?.phoneNumber ?? "");
    if (!data.data.user.phoneNumber) {
      window.uxc.setUserIdentity(data?.data?.user?.email ?? "");
    }

    window.uxc.setUserProperties({
      user_id: data?.data?.user?._id ?? "",
    });
  }

  if (!!window.heap) {
    window.heap.identify(data?.data?.user?.email ?? "");
  }
};

export const isSelectedTransferMethodBank = (selectedMethod) => selectedMethod === "ach";
export const isSelectedTransferMethodInstant = (selectedMethod) => selectedMethod === "instant";

export const getIsDebitCardExpired = (expiryMonthYear) => {
  var creditCardDate = moment(expiryMonthYear, "MM/YY");
  var today = moment();

  return !creditCardDate.isValid() || today >= creditCardDate.add(1, "months");
};

export const getPublicIPAddress = async () => {
  try {
    const response = await fetch("https://checkip.amazonaws.com/");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const ip = await response.text();
    return ip.trim(); // Trim to remove any extra spaces or new lines
  } catch (error) {
    console.error("Error fetching the IP address:", error);
    return null;
  }
};

export const formatBirthDate = (birthDate) => {
  // If birthDate is empty, return an empty string
  if (!birthDate) {
    return "";
  }

  // Parse the input date string to a Date object
  const date = new Date(birthDate);

  // Check if the date is valid
  if (isNaN(date)) {
    return "1990-01-01"; // Fallback to default date
  }

  // Format the date to 'yyyy-MM-dd' (e.g., 2023-11-05)
  const outputDate = getISOFormatDate(date);
  return outputDate;
};

export const extract5DigitZipCode = (zipCode) => {
  if (!zipCode) {
    return null;
  }
  const match = zipCode.match(/^\d{5}/);
  return match ? match[0] : null;
};

export const getAstraCardsWithSystemCardFiltered = (astraCards, stakeDebit) => {
  const unitLast4 = stakeDebit?.data?.data?.card_last_4;
  const virtualUnitLast4 = stakeDebit?.data?.data?.virtual_card_last_4;

  return (
    astraCards?.filter((card) => card.last_four_digits !== unitLast4 && card.last_four_digits !== virtualUnitLast4) ??
    []
  );
};

export const getSelectedAstraCard = (astraCards, stakeDebit, preferredCardId = null) => {
  const filteredOutCards = getAstraCardsWithSystemCardFiltered(astraCards, stakeDebit);

  if (filteredOutCards.length > 0) {
    const selectedId = !!preferredCardId ? preferredCardId : LocalStorage.read(appUtilityKeys.astraSelectedCardId);
    const foundSelectedCard = filteredOutCards.filter((card) => card.id === selectedId);
    if (!!selectedId && foundSelectedCard.length > 0) {
      return foundSelectedCard[0];
    } else {
      LocalStorage.write(appUtilityKeys.astraSelectedCardId, filteredOutCards[0].id);
      return filteredOutCards[0];
    }
  } else {
    return null;
  }
};

export const getActiveSVDAstraDebitCard = (astraCards, stakeDebit) => {
  const unitLast4 = stakeDebit?.data?.data?.card_last_4 ?? "";
  const virtualUnitLast4 = stakeDebit?.data?.data?.virtual_card_last_4 ?? "";

  const unitCard = astraCards?.find((card) => card.last_four_digits === unitLast4);
  if (unitCard) {
    return unitCard;
  }

  const virtualCard = astraCards?.find((card) => card.last_four_digits === virtualUnitLast4);
  if (virtualCard) {
    return virtualCard;
  }

  return null;
};

export const getRecurringDepositDefaultPreferences = () => {
  return { startDate: getISOFormatDate(moment().add(1, "days")), frequency: "Weekly" };
};

export const getNumberOrdinal = (num) => {
  const ords = ["th", "st", "nd", "rd"];
  const v = num % 100;
  return ords[(v - 20) % 10] || ords[v] || ords[0];
};

export const getNumberWithOrdinal = (num) => {
  return `${num}${getNumberOrdinal(num)}`;
};

export const convertObjectToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([k, v]) => formData.append(k, v));
  return formData;
};
