import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants } from "../../../Utils/constants";

export default function renderReconnectPayroll(modalContext, account, ReconnectCallback, IgnoreCallback) {
  const { updateModalComponent, setModalVariant, setModalTitle, updateModalOnCloseCallBack } = modalContext;

  updateModalComponent(() => <ReconnectPayroll account={account} ReconnectCallback={ReconnectCallback} />);
  setModalVariant(modalVariants.promptDialog);
  setModalTitle("");
  updateModalOnCloseCallBack(IgnoreCallback);
}

const ReconnectPayroll = ({ account, ReconnectCallback }) => {
  const { onModalClose } = useModalContext();

  return (
    <>
      <div className="modal-dialog-content">
        <img className="modal-status-icon border--rounded-full" src={account?.logo_url} alt="" />
        <h3 className="modal-status-title mt-5 mb-2">Connection error</h3>
        <span className="modal-content-text">Your direct deposit has expired or been disconnected.</span>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton
          className="blue-theme"
          onClick={() => {
            ReconnectCallback();
            onModalClose({ abortCallingOnCloseCallback: true });
          }}
        >
          Reconnect
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" onClick={onModalClose}>
          Ignore
        </PrimaryButton>
      </div>
    </>
  );
};
