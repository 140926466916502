import PrimaryButton from "../../../Components/Buttons/primary-button";

import { modalVariants } from "../../../Utils/constants";

import { AstraIcon } from "../../../Assets/Images";

export default function renderAstraSignUp(modalContext, onOpenAstraPortal) {
  const { updateModalComponent, setModalVariant, onModalClose } = modalContext;

  const handleOpenAstraPortal = () => {
    onOpenAstraPortal();
    onModalClose();
  };

  updateModalComponent(() => {
    return (
      <>
        <div className="modal-dialog-content">
          <AstraIcon className="d-block astra-icon mb-4 mx-auto" />
          <h3 className="modal-status-title mb-2">Instantly add funds from your debit card with Astra</h3>
          <span className="modal-content-text mb-0">
            Add funds in seconds using your debit card. Secure and convenient transactions, powered by Astra.
          </span>
        </div>
        <div className="action-container split-equally">
          <PrimaryButton className="blue-theme" onClick={handleOpenAstraPortal}>
            Next
          </PrimaryButton>
          <PrimaryButton className="secondary-colors" onClick={onModalClose}>
            Cancel
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.promptDialog);
}
