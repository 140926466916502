import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import AmountField from "../../../Components/Form Fields/amount-field";
import PrimaryButton from "../../../Components/Buttons/primary-button";
import ModalSkeleton from "../../../Components/Skeleton/modal-skeleton";

import renderSelectConnectedAccounts from "../../Modals/Connected Accounts/select-connected-accounts";

import { useConnectedAccounts, useConnectedAccountSelection, useStakeDebit } from "../../../CustomHooks/Queries";

import { useModalContext } from "../../../Contexts/ModalContext";

import { dispatchErrorToast, formatCurrency } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

import { Edit } from "../../../Assets/Icons";

export default function renderGetRecurringDepositAmountDetails(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle, pushModalStack } = modalContext;

  pushModalStack(() => renderGetRecurringDepositAmountDetails(modalContext, props));
  updateModalComponent(() => <GetRecurringDepositAmountDetails {...props} />);
  setModalTitle("Recurring Deposit");
  setModalVariant(modalVariants.fullScreenDialog);
}

const GetRecurringDepositAmountDetails = (props) => {
  const { onSchedule } = props;

  const modalContext = useModalContext();

  const { watch, handleSubmit } = useFormContext();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const { isLoading: isLoadingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) => account.account_id === connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [connectedAccounts?.data?.data?.accounts, connectedAccountSelection?.data?.data?.selected]
  );

  const dailyAddFundsLimit = useMemo(() => {
    const dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_add_cash_limit ?? 0) / 100;
    return dailyLimit;
  }, [stakeDebit]);

  const accountDescription = useMemo(() => {
    return !!selectedAccount ? `${selectedAccount?.name} (...${selectedAccount?.mask})` : "";
  }, [selectedAccount]);

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  const onSubmit = () => {
    if (!selectedAccount) {
      dispatchErrorToast("Please add a bank account to proceed", {
        alignToAppContent: true,
        key: "noConnectedAccount",
      });
    } else {
      onSchedule({ cashAmount, selectedAccount });
    }
  };

  const handleSelectConnectedAccounts = () => renderSelectConnectedAccounts(modalContext);

  const isLoading = isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingConnectedAccountSelection;

  return isLoading ? (
    <ModalSkeleton />
  ) : (
    <form className="recurring-deposit content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="top-container">
        <AmountField />
        {!!dailyAddFundsLimit && (
          <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyAddFundsLimit)}</span>
        )}
      </div>
      <div className="bottom-container">
        <div className="details">
          <div className="detail-row">
            <span className="detail-label">Account</span>
            <span className="detail-value">
              {!!selectedAccount ? accountDescription : "Connect a bank account"}
              <button className="edit" type="button" onClick={handleSelectConnectedAccounts}>
                <Edit />
              </button>
            </span>
          </div>
          <div className="detail-row">
            <span className="detail-label">Fee</span>
            <span className="detail-value">Free</span>
          </div>
          <div className="detail-row">
            <div className="info">
              Transfers take one business day to process.
              <br />
              Transfers set up on a weekend, or holiday, will occur on the next business day.
            </div>
          </div>
        </div>
        <PrimaryButton className="blue-theme mt-sm-6 mt-4" type="submit" disabled={disableSubmitButton}>
          Schedule
        </PrimaryButton>
      </div>
    </form>
  );
};
