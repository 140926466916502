import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../Components/Buttons/primary-button";

import { modalVariants } from "../../Utils/constants";

export default function renderStatusModal(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => <StatusModal {...props} />);
  setModalVariant(modalVariants.statusDialog);
  setModalTitle("");
}

export const StatusModal = ({
  StatusIcon,
  statusClassName = "",
  title,
  description,
  CTAButtonText,
  onCTAButtonClick,
  isCTAButtonLoading = false,
  secondaryCTAButtonText,
  onSecondaryCTAButtonClick,
  isSecondaryCTAButtonLoading = false,
}) => {
  return (
    <>
      <div className="modal-dialog-content">
        {!!StatusIcon && <StatusIcon className={`modal-status-icon ${statusClassName}`} />}
        <h3 className="modal-status-title mt-5 mb-2">{title}</h3>
        <span className="modal-content-text">{description}</span>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" onClick={onCTAButtonClick} disabled={isCTAButtonLoading}>
          {CTAButtonText} {isCTAButtonLoading && <CircularProgress />}
        </PrimaryButton>
        {!!onSecondaryCTAButtonClick && (
          <PrimaryButton
            className="secondary-colors"
            onClick={onSecondaryCTAButtonClick}
            disabled={isSecondaryCTAButtonLoading}
          >
            {secondaryCTAButtonText} {isSecondaryCTAButtonLoading && <CircularProgress />}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};
