import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import PrimaryButton from "../../Components/Buttons/primary-button.js";

import renderVerifyToViewVirtualCard from "../../Organisms/Modals/Virtual Card/verify-to-view-virtual-card.js";
import renderRequestPhysicalCard from "../../Organisms/Modals/Manage Card/request-physical-card.js";

import { useModalContext } from "../../Contexts/ModalContext.js";

import { dispatchErrorToast } from "../../Utils/helpers.js";

import { ArrowLeftCircle } from "../../Assets/Icons/index.js";

import "./manage-cards.scss";

export default function ManageCards() {
  const navigate = useNavigate();
  const location = useLocation();

  const modalContext = useModalContext();

  const { card_id, physical_card_id, customer_token } = location.state;

  useEffect(() => {
    document.querySelector("unit-elements-card").addEventListener("unitOnLoad", function (e) {
      if (e.detail.errors && e.detail.errors[0].status === "401") {
        dispatchErrorToast("Session expired. Please verify your phone number again.", { key: "request_error" });
        renderVerifyToViewVirtualCard(modalContext);
        navigate("/banking");
      }
    });
    //eslint-disable-next-line
  }, []);

  return (
    <div className="manage-cards">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={() => navigate(-1)}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Manage Cards</h3>
              <div className="dummy"></div>
            </div>
            <Stack direction="column" alignItems="center">
              <unit-elements-card
                card-id={card_id}
                customer-token={customer_token}
                theme="https://ui.s.unit.sh/resources/480/themes/040be43b-5e1e-4dce-b370-387ffb427487.json"
              ></unit-elements-card>
              {!physical_card_id && (
                <PrimaryButton onClick={renderRequestPhysicalCard.bind(this, modalContext)}>
                  Request physical card
                </PrimaryButton>
              )}
              {!!physical_card_id && (
                <unit-elements-card
                  card-id={physical_card_id}
                  customer-token={customer_token}
                  theme="https://ui.s.unit.sh/resources/480/themes/040be43b-5e1e-4dce-b370-387ffb427487.json"
                ></unit-elements-card>
              )}
            </Stack>
          </div>
        </div>
      </section>
    </div>
  );
}
