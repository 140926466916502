import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import { closeSnackbar } from "notistack";
import { useQueryClient } from "react-query";

import PrimaryButton from "../../../Components/Buttons/primary-button";
import PhoneNumber from "../../../Components/Form Fields/phone-number";
import OTPField from "../../../Components/Form Fields/otp-field";

import { useModalContext } from "../../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../../Contexts/AppUtilityContext";

import {
  usePhoneNumberChangeRequestVerification,
  usePhoneNumberChangeVerification,
  useUpdatePhoneNumber,
} from "../../../CustomHooks/Mutations";

import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import {
  dispatchErrorToast,
  dispatchInfoToast,
  dispatchSuccessToast,
  transformUSNumberToCountryCodeFormat,
} from "../../../Utils/helpers";

export default function renderEditPhoneNumber(modalContext, phoneNumber, sfid) {
  const { updateModalComponent, updateModalFormDefaultValues, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(EditPhoneNumber);
  updateModalFormDefaultValues({ phoneNumber, sfid });
  setModalVariant(modalVariants.formDialog);
  setModalTitle("Enter new phone");
}

const EditPhoneNumber = () => {
  const resendOtpRef = useRef(false);

  const { handleSubmit, watch, formState, getFieldState } = useFormContext();

  const [sentVerificationPhoneNumber, setSentVerificationPhoneNumber] = useState(false);

  const { setModalTitle, onModalClose, updateModalOnCloseCallBack } = useModalContext();

  const { onUserCredentialsUpdate } = useAppUtilityContext();

  const queryClient = useQueryClient();

  //Setting Dialog title on dependancy change
  useEffect(() => {
    if (!sentVerificationPhoneNumber) {
      setModalTitle("Enter new phone");
    } else {
      setModalTitle("Verify your new phone number");
    }
  }, [sentVerificationPhoneNumber, setModalTitle]);

  const phoneNumber = watch("phoneNumber");
  const otp = watch("otp");

  const { isLoading: isRequestingPhoneNumberChangeVerification, mutate: RequestPhoneNumberChangeVerification } =
    usePhoneNumberChangeRequestVerification({
      onSuccess: () => {
        setSentVerificationPhoneNumber(true);
        if (resendOtpRef.current) {
          resendOtpRef.current = false;
          dispatchSuccessToast(`New verification code sent to ${phoneNumber}`, { key: "requestPhoneNumberChange" });
        } else {
          dispatchSuccessToast(`Verification code sent to ${phoneNumber}`);
          updateModalOnCloseCallBack(() =>
            dispatchInfoToast("Changes not saved", {
              key: "requestEmailChange",
              alignToAppContent: true,
            })
          );
        }
      },
      onError: (err) => dispatchErrorToast(err?.response?.data?.error?.msg, { key: "requestPhoneNumberChange" }),
    });

  const { isLoading: isChangingPhoneNumberVerification, mutate: ChangePhoneNumberVerification } =
    usePhoneNumberChangeVerification({
      onSuccess: (data) => {
        onUserCredentialsUpdate(data);
        queryClient.refetchQueries(APIQueryKeys.userAccount);
        UpdatePhoneNumber({ userId: formState.defaultValues.sfid, phoneNumber: phoneNumber });
      },

      onError: () =>
        dispatchErrorToast("Invalid verification code, please try again", {
          key: "changePhoneNumber",
        }),
    });

  const { isLoading: isPatchingPhoneNumber, mutate: UpdatePhoneNumber } = useUpdatePhoneNumber({
    onSuccess: (data) => {
      dispatchSuccessToast("Phone number updated successfully", {
        key: "changePhoneNumber",
        alignToAppContent: true,
      });
      onModalClose({ abortCallingOnCloseCallback: true });
    },
    onError: () =>
      dispatchErrorToast("Unable to update phone number, please try again", {
        key: "changePhoneNumber",
      }),
  });

  const isLoading =
    isRequestingPhoneNumberChangeVerification || isChangingPhoneNumberVerification || isPatchingPhoneNumber;

  const onSubmit = () => {
    closeSnackbar();

    let payload = { phoneNumber: transformUSNumberToCountryCodeFormat(phoneNumber) };

    if (!sentVerificationPhoneNumber || resendOtpRef.current) {
      RequestPhoneNumberChangeVerification(payload);
    } else {
      payload.phoneNumberCode = otp;
      ChangePhoneNumberVerification(payload);
    }
  };

  const disableSubmitButton = !sentVerificationPhoneNumber
    ? !(phoneNumber ?? "") || !getFieldState("phoneNumber").isDirty
    : !(otp ?? "");

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="modal-dialog-content">
        {!sentVerificationPhoneNumber ? (
          <PhoneNumber />
        ) : (
          <>
            <p className="modal-content-text mb-4">
              Please enter the code we sent to <span className="username-badge">{phoneNumber}</span>
            </p>
            <div className="otp-field light-theme">
              <OTPField />
            </div>
            <button
              className="d-block modal-content-text text-decoration-underline mt-4 mb-0 mx-auto"
              onClick={() => {
                resendOtpRef.current = true;
                onSubmit();
              }}
            >
              Didn’t receive a text? Resend code
            </button>
          </>
        )}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" type="submit" disabled={disableSubmitButton || isLoading}>
          {!sentVerificationPhoneNumber ? "Verify" : "Update"} {isLoading && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" type="button" onClick={onModalClose}>
          Discard
        </PrimaryButton>
      </div>
    </form>
  );
};
