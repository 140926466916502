import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";

import DatePickerField from "../../../Components/Form Fields/date-picker-field";
import SSNField from "../../../Components/Form Fields/ssn-field";
import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useUpdateDateOfBirthOrSNN } from "../../../CustomHooks/Mutations";

import { validation } from "../../../Utils/validations";

import { dispatchAPIErrorToast, replaceNonNumericCharacters } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

export default function renderAddInformation(modalContext, props, defaultValues) {
  const { updateModalComponent, setModalVariant, setModalTitle, updateModalFormDefaultValues } = modalContext;
  const { requiredInfo = [] } = props;

  updateModalComponent(() => <AddInformation {...props} />);
  updateModalFormDefaultValues(defaultValues);
  setModalVariant(modalVariants.formDialog);
  setModalTitle(
    `Enter ${
      requiredInfo.length > 1
        ? "Information"
        : requiredInfo.includes("dob")
        ? "Date of Birth"
        : requiredInfo.includes("ssn")
        ? "Social Security Number"
        : ""
    }`
  );
}

const AddInformation = ({ requiredInfo = [], onSuccess, onSecondaryCTAClick }) => {
  const { handleSubmit, watch } = useFormContext();

  const dob = watch("dob");
  const ssn = watch("ssn");

  const { isLoading: isSubmittingInformation, mutate: SubmitInformation } = useUpdateDateOfBirthOrSNN({
    onSuccess: onSuccess,
    onError: () =>
      dispatchAPIErrorToast({
        key: "changeEmail",
      }),
  });

  const onSubmit = (formData) => {
    let payload = { ...formData };

    if (!!payload?.ssn) {
      const ssnNumberOnly = replaceNonNumericCharacters(ssn);
      payload.ssn = ssnNumberOnly;
    }

    SubmitInformation(payload);
  };

  const disableSubmitButton =
    requiredInfo.length > 1
      ? !(dob ?? "") || !(ssn ?? "")
      : requiredInfo.includes("dob")
      ? !(dob ?? "")
      : requiredInfo.includes("ssn")
      ? !(ssn ?? "")
      : false;

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="modal-dialog-content">
        <p className="modal-content-text mb-3">
          {`Please ${
            requiredInfo.length > 1
              ? "provide the following information"
              : requiredInfo.includes("dob")
              ? "enter your date of birth"
              : requiredInfo.includes("ssn")
              ? "enter your 9-digit SSN"
              : ""
          } to opt-in into credit reporting`}
        </p>
        {requiredInfo.includes("dob") && (
          <DatePickerField
            name="dob"
            label="Date of Birth"
            validations={{
              isAValidDate: (dob) => validation.date.isAValidDate(dob) || "Please enter a valid date.",
              isAbove18YearsOfAge: (dob) =>
                validation.date.isAbove18YearsOfAge(dob) || "You must be at least 18 years old.",
            }}
            datePickerProps={{ disableFuture: true }}
            textFieldProps={{ placeholder: "MM/DD/YYYY", fullWidth: true }}
          />
        )}
        {requiredInfo.includes("ssn") && <SSNField className="mt-5" autoFocus={requiredInfo.length === 1} />}
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="blue-theme" type="submit" disabled={disableSubmitButton || isSubmittingInformation}>
          Submit{isSubmittingInformation && <CircularProgress />}
        </PrimaryButton>
        <PrimaryButton className="secondary-colors" type="button" onClick={onSecondaryCTAClick}>
          Back
        </PrimaryButton>
      </div>
    </form>
  );
};
