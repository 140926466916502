import { Chasity, Danielle, Fred, Larissa, Nick } from "../Assets/Images";
import { Banking, Renting, Profile, CheckCircleWithShadow, CloseWithShadow } from "../Assets/Icons";

export const mediumScreenStartFrom = 629;

export const reviewersData = [
  {
    profileImageLink: Danielle,
    profileImageName: "danielle",
    userFullName: "Danielle Hunter",
    userStatus: "$1,358 total cash back earned",
    reviewString:
      "Being able to earn cash back on making on-time rental payments is a huge plus! It helps to keep money in your pocket or being able to allocate those savings to other bills.",
    rating: 4.9,
  },
  {
    profileImageLink: Chasity,
    profileImageName: "chasity",
    userFullName: "Chasity English",
    userStatus: "$981 total cash back earned",
    reviewString:
      "TThis app is really easy to use no hassle and it helps me keep up with my rent being on time! The extra money I get back helps me a lot to as well.",
    rating: 4.7,
  },
  {
    profileImageLink: Nick,
    profileImageName: "nick",
    userFullName: "Nick Henderson",
    userStatus: "$1,095 total cash back earned",
    reviewString:
      "After paying your rent on time you get a reward and it eases the pressure of making it until payday. I wish this had been available at all the other properties I have rented at.",
    rating: 4.8,
  },
  {
    profileImageLink: Fred,
    profileImageName: "fred",
    userFullName: "Fred Bailey",
    userStatus: "$704 total cash back earned",
    reviewString:
      "Stake is a great incentive tool used to get residents to pay rent on time and get rewarded for doing so! Stake helped me out in times I needed the extra funds to pay bills or get food.",
    rating: 4.7,
  },
  {
    profileImageLink: Larissa,
    profileImageName: "larissa",
    userFullName: "Larissa Smith",
    userStatus: "$1,108 total cash back earned",
    reviewString:
      "Love this app! It helps me stay on top of my payments and makes me want to pay early every month just to save, whoever thought of this was genius, I love how it tracks it and it also tells me how much I would save if I pay on a certain day.",
    rating: 4.9,
  },
];

export const phoneNumberMask = "(000) 000-0000";
export const ssnMask = "000-00-0000";

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ToastVariants = ["success", "error", "warning", "info"];

export const SuccessToastVariant = ToastVariants[0];
export const ErrorToastVariant = ToastVariants[1];
export const WarningToastVariant = ToastVariants[2];
export const InfoToastVariant = ToastVariants[3];

export const navItems = [
  { Icon: Banking, navItemName: "Banking", navRoute: "/banking" },
  { Icon: Renting, navItemName: "Renting", navRoute: "/renting" },
  { Icon: Profile, navItemName: "Profile", navRoute: "/profile" },
];

export const modalVariants = {
  tooltipDialog: "tooltip-dialog",
  infoDialog: "info-dialog",
  stepByStepDialog: "step-by-step-dialog",
  formDialog: "form-dialog",
  statusDialog: "status-dialog",
  promptDialog: "prompt-dialog",
  flexDialog: "flex-dialog",
  fullScreenDialog: "fullscreen-dialog",
};

export const appUtilityKeys = {
  authKey: "auth_key",
  loggedInUserData: "user",
  transferToken: "transfer_token",
  astraToken: "astra_token",
  astraRefreshToken: "astra_refresh_token",
  astraIntentId: "astra_intent_id",
  lastAstraTokenFetchTime: "last_astra_token_fetch_time",
  astraSelectedCardId: "astra_selected_card_id",
  hasVisitedWelcomeScreen: (userId) => `hasVisitedWelcomeScreen-${userId}`,
  animateCurrentBalanceFrom: (userId) => `animateCurrentBalanceFrom-${userId}`,
};

export const isDevNodeEnv = process.env.NODE_ENV === "development";
export const isDevReactEnv = process.env.REACT_APP_ENV === "development";

export const debugProps = isDevReactEnv ? { debug: true } : {};

export const applicationJsonContentType = "application/json";
export const formDataContentType = "multipart/form-data";

export const neglectEndpointsForUnauthorisedErrors = [
  "/mobile/loginRequestSms",
  "/mobile/loginSms",
  "/mobile/emailChangeRequestVerification",
  "/mobile/emailChangeVerification",
  "/mobile/phoneNumberChangeRequestSms",
  "/mobile/phoneNumberChangeSms",
  "/create_astra_account",
  "/mobile/stake_payroll/",
  "/mobile/stake_payroll/get_link_token/",
];

export const APIQueryKeys = {
  mobileAppSummary: ["mobile-app-summary"],
  platformSummaryStats: ["platform-summary-stats"],
  deals: ["deals"],
  unclaimedTransactions: ["unclaimed-transactions"],
  transferToken: ["transfer_token"],
  alternativeLoginFlag: ["alternative-login-flag"],
  connectedAccounts: ["connected-accounts"],
  connectedAccountsSelection: ["connected-account-selection"],
  stakeDebit: ["stake-debit"],
  stakeDebitStatements: ["stake-debit-statements"],
  payrollAccount: ["payroll-account"],
  creditReporting: ["credit-reporting"],
  paymentHistories: ["payment-histories"],
  userAccount: ["user-account"],
  activities: ["activities"],
  customerTokenVerificationSMS: ["customer-token-verification-sms"],
  astraCards: ["astra-cards"],
  astraAccounts: ["astra-accounts"],
  routesCapabilities: ["astra-routes-capabilities"],
  recurringDeposit: ["recurring-deposit"],
};

export const longMonthDateFormat = { year: "numeric", month: "long", day: "numeric" };
export const numericDateFormat = { year: "numeric", month: "numeric", day: "numeric" };
export const nilYearLongMonthDateFormat = { year: undefined, month: "long", day: "numeric" };
export const shortDateTimeFormat = { timeStyle: "short", dateStyle: "short" };

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const SuccessfulTransferStatusModalProps = {
  StatusIcon: CheckCircleWithShadow,
  title: "Your transfer was initiated.",
  CTAButtonText: "Done",
};

export const FailedTransferStatusModalProps = {
  StatusIcon: CloseWithShadow,
  statusClassName: "error",
  title: "Sorry, your transfer could not be initiated.",
  description: "Try again in a few minutes. Contact Stake if the problem persists.",
  CTAButtonText: "Done",
};

export const SuccessfulRecurringDepositStatusModalProps = {
  StatusIcon: CheckCircleWithShadow,
  title: "Deposit Scheduled!",
  CTAButtonText: "View Summary",
  secondaryCTAButtonText: "Go to Banking",
};

export const FailedRecurringDepositStatusModalProps = {
  StatusIcon: CloseWithShadow,
  statusClassName: "error",
  title: "Sorry, there was an issue with your recurring deposit",
  description:
    "Please try again in a few minutes. If the problem persists, contact Stake’s support team for assistance.",
  CTAButtonText: "Try Again",
  secondaryCTAButtonText: "Cancel",
};

export const SuccessClearRecurringDepositStatusModalProps = {
  StatusIcon: CheckCircleWithShadow,
  title: "Recurring Deposit Removed Successfully",
  description:
    "Your existing recurring deposit has been successfully removed. If you wish to set up a new deposit, you can do so at any time.",
  CTAButtonText: "Done",
};

export const ClearRecurringDepositFailureStatusModalProps = {
  StatusIcon: CloseWithShadow,
  statusClassName: "error",
  title: "Error Removing Recurring Deposit",
  description:
    "Unfortunately, there was an issue removing your recurring deposit. Please try again in a few minutes. If the problem persists, contact Stake’s support team for assistance.",
  CTAButtonText: "Try Again",
  secondaryCTAButtonText: "Cancel",
};

export const fontFace400 = {
  "@font-face": {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "400",
    "font-display": "swap",
    src: "url(https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format('woff2')",
    "unicode-range":
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
  },
};

export const fontFace500 = {
  "@font-face": {
    "font-family": "Poppins",
    "font-style": "normal",
    "font-weight": "500",
    "font-display": "swap",
    src: "url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format('woff2')",
    "unicode-range":
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD",
  },
};

export const astraActionTypeLinkCard = "LINK_CARD";
