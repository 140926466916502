import { useIMask } from "react-imask";
import { useFormContext } from "react-hook-form";

import TextField from "./text-field";

import { validation } from "../../Utils/validations";

import { phoneNumberMask } from "../../Utils/constants";

import { removeControlCharacters } from "../../Utils/helpers";

export default function PhoneNumber() {
  const { register, getFieldState, formState } = useFormContext();

  const phoneNumberInputProps = register("phoneNumber", {
    required: true,
    validate: (phoneNumber) => validation.number.isUSPhoneNumber(phoneNumber) || "Please enter a valid phone number.",
  });

  const { ref } = useIMask(
    {
      mask: phoneNumberMask,
      eager: true,
    },
    {
      onAccept: (value) =>
        phoneNumberInputProps.onChange?.({ target: { name: "phoneNumber", value: removeControlCharacters(value) } }),
    }
  );

  const { error: phoneNumberFieldError } = getFieldState(phoneNumberInputProps.name, formState);

  return (
    <TextField
      {...phoneNumberInputProps}
      ref={(ele) => {
        ref.current = ele;
        phoneNumberInputProps.ref(ele);
      }}
      onBlur={(e) =>
        phoneNumberInputProps.onBlur({
          target: { name: "phoneNumber", value: removeControlCharacters(e.target.value) },
        })
      }
      label="Phone"
      autoFocus
      errorMessage={phoneNumberFieldError?.message}
    />
  );
}
