import { useState } from "react";
import { useQueryClient } from "react-query";
import { useIntercom } from "react-use-intercom";

import renderTermsOfService from "../Organisms/Modals/Terms of Service/terms-of-service";

import { useModalContext } from "../Contexts/ModalContext";

import {
  useDeals,
  useMobileAppSummary,
  usePlatformSummaryStats,
  useTransferToken,
  useUnclaimedTransactions,
  useUserAccount,
} from "./Queries";

import { LocalStorage } from "../Utils/helpers";
import { APIQueryKeys, appUtilityKeys } from "../Utils/constants";

export default function usePrefetchOnLogin({ authKey, hasVisitedWelcomeScreen }) {
  const queryClient = useQueryClient();
  const modalContext = useModalContext();

  const { update } = useIntercom();

  const { modalVisibility } = modalContext;

  const [hasTransferToken, setHasTransferToken] = useState(!!LocalStorage.read(appUtilityKeys.transferToken));

  const {
    isLoading: isLoadingDeals,
    isSuccess: isDealsFetchSuccess,
    isError: isDealsFetchError,
  } = useDeals({
    enabled: !!authKey,
    onSuccess: ({ data }) => {
      if (data?.data?.currentDeal?.show_tos_flag && !modalVisibility) renderTermsOfService(modalContext);
    },
  });

  const {
    isLoading: isLoadingUserAccountData,
    isSuccess: isUserAccountDataFetchSuccess,
    isError: isUserAccountDataFetchError,
  } = useUserAccount({
    enabled: !!authKey,
    onSuccess: (userAccountData) => {
      !!authKey &&
        update({
          userId: userAccountData?.data?.data?.user?._id,
          name: `${userAccountData?.data?.data?.user?.firstName} ${userAccountData?.data?.data?.user?.lastName} there`,
          email: userAccountData?.data?.data?.user?.email,
          phone: userAccountData?.data?.data?.user?.phoneNumber,
        });
    },
  });

  const {
    isLoading: isTransferTokenLoading,
    isSuccess: isTransferTokenFetchSuccess,
    isError: isTransferTokenFetchError,
  } = useTransferToken({
    onSuccess: ({ data }) => {
      LocalStorage.write(appUtilityKeys.transferToken, data?.data?.token);
      setHasTransferToken(!!data?.data?.token);
      queryClient.refetchQueries(APIQueryKeys.unclaimedTransactions);
    },
    enabled: !!authKey && !hasTransferToken,
  });

  const {
    isLoading: isLoadingUnclaimedTransactions,
    isSuccess: isUnclaimedTransactionsFetchSuccess,
    isError: isUnclaimedTransactionsFetchError,
    data: unclaimedTransactionsData,
  } = useUnclaimedTransactions({ enabled: !!authKey && hasTransferToken });

  const {
    isLoading: isLoadingMobileAppSummaryAPI,
    isSuccess: isMobileAppSummaryFetchSuccess,
    isError: isMobileAppSummaryFetchError,
  } = useMobileAppSummary({
    enabled: !hasVisitedWelcomeScreen && !!authKey,
  });

  const {
    isLoading: isLoadingPlatformSummaryStatsAPI,
    isSuccess: isPlatformSummaryStatsFetchSuccess,
    isError: isPlatformSummaryStatsFetchError,
  } = usePlatformSummaryStats({ enabled: !hasVisitedWelcomeScreen && !!authKey });

  return {
    isLoading:
      isLoadingDeals ||
      isLoadingUserAccountData ||
      isTransferTokenLoading ||
      isLoadingUnclaimedTransactions ||
      isLoadingMobileAppSummaryAPI ||
      isLoadingPlatformSummaryStatsAPI,
    isSuccess:
      isDealsFetchSuccess &&
      isUserAccountDataFetchSuccess &&
      isTransferTokenFetchSuccess &&
      isUnclaimedTransactionsFetchSuccess &&
      (!!hasVisitedWelcomeScreen ||
        (!hasVisitedWelcomeScreen && isMobileAppSummaryFetchSuccess && isPlatformSummaryStatsFetchSuccess)),
    isError:
      isDealsFetchError ||
      isUserAccountDataFetchError ||
      isTransferTokenFetchError ||
      isUnclaimedTransactionsFetchError ||
      isMobileAppSummaryFetchError ||
      isPlatformSummaryStatsFetchError,
    hasUnclaimedTransactionsData: unclaimedTransactionsData?.data?.data?.unclaimed_transactions?.length > 0,
  };
}
