import { Divider, Stack } from "@mui/material";

import { modalVariants } from "../../../../Utils/constants";

import { formatCurrency } from "../../../../Utils/helpers";

import { getActivityDateOrStatus } from "../../../../Utils/api-data-transforms";

import "./activity-list.scss";

export default function renderActivityList(modalContext, bankActivityList = []) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => (
    <div className="modal-dialog-content include-mb">
      <Stack className="modal-activity-list" direction="column" divider={<Divider className="activity-divider" />}>
        {bankActivityList.map((activity) => (
          <div key={activity._id} className="activity-item">
            <Stack className="row-1" direction="row" alignItems="center" justifyContent="space-between">
              <span className="activity-date">{getActivityDateOrStatus(activity)}</span>
              <span className="activity-amount">
                {activity.direction === "Credit" ? "+" : "-"}
                {formatCurrency(Math.abs(activity.amount))}
              </span>
            </Stack>
            <Stack className="row-2" direction="row" alignItems="center" justifyContent="space-between">
              <span className="activity-name">{activity.category}</span>
              <span className="balance-after-activity">{formatCurrency(activity.balance)}</span>
            </Stack>
          </div>
        ))}
      </Stack>
    </div>
  ));
  setModalVariant(modalVariants.flexDialog);
  setModalTitle("All Activity");
}
