import { IntercomProvider } from "react-use-intercom";

export function SupportProvider({ children }) {
  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_API_KEY}
      autoBoot={true}
      autoBootProps={{
        name: `there`,
        hideDefaultLauncher: true,
      }}
    >
      {children}
    </IntercomProvider>
  );
}
