import { Outlet, useLocation } from "react-router-dom";

import SideBar from "../App Utility Bar/sidebar";
import NavBar from "../App Utility Bar/navbar";

import { getHeaderBgColorForPage } from "../../Utils/helpers";

import { StakeLogo } from "../../Assets/Images";

export default function AppComponentWrapper() {
  const { pathname } = useLocation();

  const headerBgColor = getHeaderBgColorForPage(pathname);

  return (
    <div className="app-utility-container">
      <SideBar />
      <header className={headerBgColor}>
        <StakeLogo className="logo" />
      </header>
      <div className="app-content">{<Outlet />}</div>
      <NavBar />
    </div>
  );
}
