import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

import "./earn-more-card.scss";

export default function EarnMore({
  title,
  description,
  cardButtonText,
  onCardButtonClick,
  NestComponent,
  isLoading,
  CardIllustrationComponent,
}) {
  return (
    <div className="earn-more-card" onClick={onCardButtonClick}>
      <div className="card-content">
        <div className="texts">
          <div className="title">{title}</div>
          {!!description && <div className="description">{description}</div>}
        </div>
        {!!NestComponent ? (
          <NestComponent />
        ) : (
          <button className="button">{isLoading ? <CircularProgress /> : cardButtonText}</button>
        )}
      </div>
      <CardIllustrationComponent className="illustration" />
    </div>
  );
}

EarnMore.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cardButtonText: PropTypes.string,
  onCardButtonClick: PropTypes.func,
  cardIllustration: PropTypes.string.isRequired,
};
