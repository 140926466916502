import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderStatusModal from "../status-modal";

import { useUserAccount } from "../../../CustomHooks/Queries";
import { useRequestStakeDebitCard } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { SVD_Single_Card } from "../../../Assets/Images";
import { CheckCircleWithShadow } from "../../../Assets/Icons";

export default function renderRequestPhysicalCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalPromoComponent, setModalTitle, setModalClassName } =
    modalContext;

  updateModalComponent(RequestPhysicalCard);
  setModalPromoComponent(null);
  setModalTitle("");
  setModalVariant(modalVariants.flexDialog);
  setModalClassName("position-close-button-over-content");
}

const RequestPhysicalCard = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const modalContext = useModalContext();

  const { onModalClose } = modalContext;

  const { data: userAccount } = useUserAccount();

  const { isLoading: isRequestingSVD, mutate: RequestStakeDebitCard } = useRequestStakeDebitCard({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.stakeDebit);
      navigate("banking");
      renderStatusModal(modalContext, {
        StatusIcon: CheckCircleWithShadow,
        title: "Your Physical Card is on its Way!",
        description:
          "You can activate your card once it arrives. Track the expected arrival date from the 'Earn More' section on the banking page .",
        CTAButtonText: "Done",
        onCTAButtonClick: onModalClose,
      });
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const user = userAccount?.data?.data?.user;

  const cityStateZipCode = useMemo(() => `${user?.city}, ${user?.state} ${user?.zipCode}`, [user]);

  return (
    <>
      <div className="modal-image svd-single-card">
        <img src={SVD_Single_Card} alt="" />
      </div>
      <div className="modal-dialog-content">
        <span className="modal-status-title mt-6 mb-5">You’ve been approved!</span>
        <span className="text-lg-regular color--medium-grey mb-2">We’re sending your card to:</span>
        <span className="text-lg-medium color--charcoal mb-1">
          {user?.firstName} {user?.lastName}
        </span>
        <span className="text-lg-medium color--charcoal mb-1">{user?.address1}</span>
        {!!user?.address2 && user?.address2.length > 0 && (
          <span className="text-lg-medium color--charcoal mb-1">{user?.address2}</span>
        )}
        <span className="text-lg-medium color--charcoal mb-0">{cityStateZipCode}</span>
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={RequestStakeDebitCard} disabled={isRequestingSVD}>
          Confirm and Finish {isRequestingSVD && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};
