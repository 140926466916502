import { Navigate, useRoutes } from "react-router-dom";

import Login from "./Pages/Login";
import LoginWithUniqueContactID from "./Pages/Login/login-with-unique-contact-ID";
import Welcome from "./Pages/Welcome";
import Unclaimed from "./Pages/Unclaimed";
import Banking from "./Pages/Banking";
import BankStatements from "./Pages/Bank Statements";
import Renting from "./Pages/Renting";
import Profile from "./Pages/Profile";
import PersonalInformation from "./Pages/Personal Information";
import ConnectedAccountsPage from "./Pages/Connected Accounts";
import DebitCards from "./Pages/Debit Cards";
import AddCash from "./Pages/Cash Transaction Pages/add-cash";
import CashOut from "./Pages/Cash Transaction Pages/cash-out";
import BankingLimits from "./Pages/Banking Limits";
import DirectDeposit from "./Pages/Direct Deposit";
import RecurringDepositsPage from "./Pages/Recurring Deposits";
import ManageCards from "./Pages/Manage Cards";

import AppComponentWrapper from "./Organisms/Wrappers/app-component-wrapper";

import { RedirectContextProvider } from "./Contexts/RedirectContext";

function Routes() {
  let routes = useRoutes([
    {
      path: "/",
      element: <RedirectContextProvider />,
      children: [
        { path: "login", element: <Login /> },
        { path: "welcome", element: <Welcome /> },
        { path: "unclaimed", element: <Unclaimed /> },
        {
          path: "/",
          element: <AppComponentWrapper />,
          children: [
            {
              path: "banking",
              children: [
                { path: "", element: <Banking /> },
                { path: "statement", element: <BankStatements /> },
                { path: "connected-accounts", element: <ConnectedAccountsPage /> },
                { path: "debit-cards", element: <DebitCards /> },
                { path: "add-cash", element: <AddCash /> },
                { path: "cash-out", element: <CashOut /> },
                { path: "banking-limits", element: <BankingLimits /> },
                { path: "direct-deposit", element: <DirectDeposit /> },
                { path: "recurring-deposits", element: <RecurringDepositsPage /> },
                { path: "manage-cards", element: <ManageCards /> },
              ],
            },
            { path: "renting", element: <Renting /> },
            {
              path: "profile",
              children: [
                { path: "", element: <Profile /> },
                { path: "personal-information", element: <PersonalInformation /> },
              ],
            },
            { path: "/", element: <Navigate to="/login" /> },
          ],
        },
        { path: "/", element: <Navigate to="/login" /> },
      ],
    },
    { path: "login-with-unique-id", element: <LoginWithUniqueContactID /> },
    { path: "*", element: <Navigate to="/login" /> },
  ]);
  return routes;
}

export default Routes;
