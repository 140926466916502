import { convertObjectToFormData, LocalStorage } from "./helpers";
import { appUtilityKeys } from "./constants";

export const getFetchAstraTokenPayload = () => {
  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);
  const refreshToken = LocalStorage.read(appUtilityKeys.astraRefreshToken);

  const payload = {
    code: null,
    redirect_uri: process.env.REACT_APP_ASTRA_REDIRECT_URL,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    user_id: user?._id ?? "",
  };

  return convertObjectToFormData(payload);
};

export const getTransferRoutinePayload = (attributes) => {
  const { sourceCard, destinationCard, amount } = attributes;

  const user = LocalStorage.read(appUtilityKeys.loggedInUserData);

  const payload = {
    destination_id: destinationCard?.id ?? "",
    destination_user_id: destinationCard?.user_id ?? "",
    amount: amount,
    user_id: user._id ?? "",
  };

  if (!!sourceCard) {
    payload.source_id = sourceCard?.id ?? "";
  }

  return convertObjectToFormData(payload);
};
