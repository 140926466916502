import { useMemo } from "react";
import { useQueryClient } from "react-query";

import { AlertConfirmationModal } from "../alert-confirmation-modal";

import useConnectBankAccount from "../../../CustomHooks/useConnectBankAccount";

import { useModalContext } from "../../../Contexts/ModalContext";

import { useConnectedAccounts } from "../../../CustomHooks/Queries";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

export default function renderMandateBankAccountAddition(modalContext, props) {
  const { updateModalComponent, setModalVariant } = modalContext;

  updateModalComponent(() => <MandateBankAccountAddition {...props} />);
  setModalVariant(modalVariants.promptDialog);
}

function MandateBankAccountAddition({ title, description, onSuccess }) {
  const { setModalClassName, onModalClose } = useModalContext();

  const queryClient = useQueryClient();

  const { data: connectedAccounts } = useConnectedAccounts();

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.connectedAccounts);
      queryClient.refetchQueries(APIQueryKeys.connectedAccountsSelection);
      onSuccess?.();
      onModalClose();
    },
    onError: () => {
      dispatchAPIErrorToast({ alignToAppContent: true });
      onModalClose();
    },
    onInitiatingSave: () => setModalClassName(null),
    onExit: () => setModalClassName(null),
  });

  const AddBank = () => {
    if (!isSavingAccount && plaidReady) {
      setModalClassName("d-none");
      openPlaid();
    }
  };

  return (
    <AlertConfirmationModal
      variant="alert-neutral"
      title={!!title ? title : !isBankAccountConnected ? "Connect Bank Account" : "Add new bank account"}
      description={
        !!description
          ? description
          : !isBankAccountConnected
          ? ""
          : "Adding a new bank account will replace your existing account for future transactions."
      }
      CTAButtonText={!isBankAccountConnected ? "Connect Bank" : "Add New Account"}
      isCTALoading={isSavingAccount || !plaidReady}
      onCTAButtonClick={AddBank}
      secondaryCTAButtonText={"Cancel"}
      onSecondaryCTAButtonClick={() => onModalClose()}
    />
  );
}
