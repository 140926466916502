import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import renderVerifyToViewVirtualCard from "./verify-to-view-virtual-card";
import renderStatusModal from "../status-modal";

import { useRequestVirtualStakeDebit } from "../../../CustomHooks/Mutations";

import { useModalContext } from "../../../Contexts/ModalContext";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { APIQueryKeys, modalVariants } from "../../../Utils/constants";

import { CheckCircleWithShadow } from "../../../Assets/Icons";
import { StakeCardFrontSide } from "../../../Assets/Images";

const SuccessfullyRequestedVirtualCardStatusModalProps = {
  StatusIcon: CheckCircleWithShadow,
  title: "Activation success!",
  description: 'Access your virtual debit card from the "banking services" on the banking page',
  CTAButtonText: "View virtual debit card",
};

export default function renderRequestVirtualCard(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(RequestVirtualCard);
  setModalTitle("Manage Virtual Debit Card");
  setModalVariant(modalVariants.formDialog);
}

const RequestVirtualCard = () => {
  const modalContext = useModalContext();

  const queryClient = useQueryClient();

  const { isLoading: isRequestingVirtualDebit, mutate: RequestStakeVirtualDebit } = useRequestVirtualStakeDebit({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.stakeDebit);
      renderStatusModal(modalContext, {
        ...SuccessfullyRequestedVirtualCardStatusModalProps,
        onCTAButtonClick: renderVerifyToViewVirtualCard.bind(this, modalContext),
      });
    },
    onError: () => dispatchAPIErrorToast(),
  });

  return (
    <>
      <div className="modal-dialog-content">
        <img className="stake-card-front-side" src={StakeCardFrontSide} alt="" />
      </div>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={RequestStakeVirtualDebit} disabled={isRequestingVirtualDebit}>
          Activate Card {isRequestingVirtualDebit && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};
