import { formatCurrency, formatDate } from "./helpers";
import { longMonthDateFormat, shortDateTimeFormat } from "./constants";

export const sortedAndGroupedPayments = (payments, groupPayments = false) => {
  if (!payments) return [];

  // Sort the payments by year (descending) and then by month (descending)
  const sortedPayments = payments.sort((a, b) => {
    const yearDiff = parseInt(b.year__c) - parseInt(a.year__c);
    if (yearDiff !== 0) return yearDiff;
    return parseInt(b.month__c) - parseInt(a.month__c);
  });

  if (!groupPayments) return sortedPayments;

  // Group the sorted payments by year
  const groupedPayments = sortedPayments.reduce((acc, payment) => {
    const year = payment.year__c;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(payment);
    return acc;
  }, {});

  // Convert the grouped object to an array of { year, payments } objects
  return Object.entries(groupedPayments)
    .sort((a, b) => parseInt(b[0]) - parseInt(a[0])) // Sort years descending
    .map(([year, payments]) => ({
      year,
      payments,
    }));
};

// Helper function to sort activities by date (newest first)
export const sortActivitiesByDate = (activities) => {
  return [...activities].sort((a, b) => b.datetime - a.datetime);
};

// Helper function to get the activity date or status
export const getActivityDateOrStatus = (activity) => {
  const { status, datetime } = activity;
  const trimmedStatus = status.trim();

  if (!trimmedStatus || trimmedStatus === "PendingReview") {
    return trimmedStatus === "PendingReview" ? "Pending" : formatDate(datetime, longMonthDateFormat);
  }

  return trimmedStatus;
};

// Helper function to transform payroll accounts
export const getTransformedPayrollAccounts = (accounts = []) => {
  return accounts?.map((account) => {
    let allocationAmount = null;
    let remainderAmount = null;

    account?.allocations
      ?.filter((allocation) => allocation.allocationType === "remainder")
      ?.forEach((allocation) => {
        if (!!allocation?.amount) {
          remainderAmount = (remainderAmount ?? 0) + allocation?.amount;
        }
      });

    account?.allocations
      ?.filter((allocation) => allocation.allocationType !== "remainder")
      ?.forEach((allocation) => {
        if (!!allocation?.amount) {
          allocationAmount = (allocationAmount ?? 0) + allocation?.amount;
        }
      });

    if (!!allocationAmount) {
      allocationAmount = allocationAmount / 100;
    }

    if (!!remainderAmount) {
      remainderAmount = remainderAmount / 100;
    }

    let amountLabel = "";
    let updatedOnLabel = null;
    let connectionStatusLabel = "";

    if (remainderAmount == null && allocationAmount != null) {
      let amount = formatCurrency(allocationAmount)?.replace(".00", "");
      switch (account.connection_status) {
        case "success":
          amountLabel = amount + " every paycheck";
          break;
        case "pending":
          amountLabel = amount + " per paycheck";
          break;
        case "disconnected":
          amountLabel = amount + " per paycheck";
          break;
        default:
          amountLabel = "";
      }
    } else if (remainderAmount == null && allocationAmount == null) {
      amountLabel = "Entire paycheck";
    } else if (remainderAmount != null && allocationAmount == null) {
      let amount = formatCurrency(remainderAmount)?.replace(".00", "");

      amountLabel = `Entire paycheck minus ${amount} to other accounts`;
    }

    switch (account.connection_status) {
      case "success":
        updatedOnLabel = `Last update: ${formatDate(account.updated_at, shortDateTimeFormat)}`;
        connectionStatusLabel = "Connected";
        break;
      case "pending":
        updatedOnLabel = null;
        connectionStatusLabel = "Pending";
        break;
      case "disconnected":
        updatedOnLabel = "Last update: N/A. Please reconnect";
        connectionStatusLabel = "Please Reconnect";
        break;
      default:
        updatedOnLabel = null;
        connectionStatusLabel = "";
    }

    return { ...account, amountLabel, updatedOnLabel, connectionStatusLabel };
  });
};
